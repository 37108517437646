import React from 'react';
import { createSvgIcon } from '@ovpn-ui/icons';
import { colors } from '@ovpn-ui/styles';

export default createSvgIcon(
    <>
        <path
            fill={colors.darkSlateBlue}
            d="M238.264 8.637h4.107a.464.464 0 0 1 .332.13.455.455 0 0 1 .138.326v28.026a.478.478 0 0 1-.48.475h-3.647v-.067l-12.017-20.472v20.064a.478.478 0 0 1-.48.475h-4.166a.463.463 0 0 1-.329-.136.45.45 0 0 1-.131-.33V9.259a.607.607 0 0 1 .18-.442.624.624 0 0 1 .447-.18h5.058l10.507 17.855V9.111c0-.262.215-.475.481-.475zm-40.806.591V37.09c0 .273.224.495.5.495h5.421c.259 0 .47-.209.47-.466V26.393h5.881s8.028-.416 8.028-8.921c0-8.505-8.136-8.796-8.136-8.796h-11.566a.553.553 0 0 0-.424.144.542.542 0 0 0-.174.408zm10.508 3.812a4.167 4.167 0 0 1 3.134 1.299 4.071 4.071 0 0 1 1.1 3.181 4.172 4.172 0 0 1-1.138 3.143 4.265 4.265 0 0 1-3.096 1.328h-4.225v-8.66c0-.388.294-.34.294-.34l3.931.049zm-25.22 24.205l10.517-27.939a.458.458 0 0 0-.056-.434.471.471 0 0 0-.395-.196h-4.714a.49.49 0 0 0-.451.3l-6.861 18.31-6.911-18.31a.47.47 0 0 0-.441-.3h-4.715a.474.474 0 0 0-.395.196.46.46 0 0 0-.055.434l10.517 27.94a.499.499 0 0 0 .451.31h3.087a.5.5 0 0 0 .461-.31h-.039zM23.644 16.058c3.654.443 6.396 3.522 6.38 7.164-.005 2.955-1.842 5.605-4.627 6.672l2.744 14.75H17.428l2.793-14.72c-3.45-1.274-5.39-4.9-4.517-8.438.874-3.539 4.285-5.871 7.94-5.428z"
            clipRule="evenodd"
            fillRule="evenodd"
        />
        <path
            fill={colors.dustyOrange}
            d="M22.892.911c12.461 0 22.635 9.86 22.892 22.185.007 8.014-4.314 15.421-11.33 19.425l-1.461-9.61a13.199 13.199 0 0 0 3.558-9.01c-.198-7.322-6.255-13.156-13.659-13.156-7.403 0-13.46 5.834-13.659 13.156 0 3.313 1.251 6.506 3.51 8.951l-1.47 9.64C4.292 38.473 0 31.086 0 23.096.258 10.77 10.431.911 22.892.911zm38.703 22.121c-.041 8.166 6.606 14.824 14.86 14.883a15.1 15.1 0 0 0 10.635-4.31 14.779 14.779 0 0 0 4.44-10.49c.005-8.165-6.672-14.794-14.925-14.817-8.254-.023-14.969 6.569-15.01 14.734zm23.073.039c.025 4.517-3.648 8.202-8.214 8.24a8.358 8.358 0 0 1-5.892-2.385 8.18 8.18 0 0 1-2.459-5.81c0-4.517 3.695-8.182 8.26-8.194 4.566-.013 8.28 3.632 8.305 8.149zm10.037 14.02V9.228a.54.54 0 0 1 .174-.41.552.552 0 0 1 .424-.143h11.557s8.145.29 8.145 8.796c0 8.505-8.028 8.922-8.028 8.922h-5.832v10.725a.478.478 0 0 1-.47.466h-5.47a.497.497 0 0 1-.5-.495zm13.639-22.752a4.156 4.156 0 0 0-3.131-1.3l-3.931-.048s-.294-.048-.294.34v8.66h4.225a4.258 4.258 0 0 0 3.093-1.328 4.168 4.168 0 0 0 1.132-3.143 4.068 4.068 0 0 0-1.094-3.18zm10.249 23.246a.468.468 0 0 1-.471-.466V9.112a.464.464 0 0 1 .48-.465h19.437c.267 0 .485.21.491.475v4.073a.472.472 0 0 1-.481.466h-13.173a.478.478 0 0 0-.471.465v5.489c0 .257.211.465.471.465h8.704a.463.463 0 0 1 .335.134.452.452 0 0 1 .135.332v4.092a.455.455 0 0 1-.47.456h-8.704a.478.478 0 0 0-.471.466v6.546a.468.468 0 0 0 .471.455h13.173c.262 0 .475.207.481.466v4.092a.472.472 0 0 1-.481.465h-19.456zm44.549-28.919h-4.107a.478.478 0 0 0-.48.475V26.52L148.047 8.666h-5.077a.576.576 0 0 0-.579.563v27.93c0 .256.211.465.471.465h4.156a.483.483 0 0 0 .346-.136.473.473 0 0 0 .144-.34V17.084l11.978 20.472v.068h3.646c.128 0 .25-.05.34-.14.09-.089.14-.21.14-.336V9.122a.451.451 0 0 0-.138-.326.464.464 0 0 0-.332-.13z"
            clipRule="evenodd"
            fillRule="evenodd"
        />
        <path
            fill={colors.darkSlateBlue}
            d="M254.636 9.043a3.28 3.28 0 1 1-6.56 0 3.28 3.28 0 0 1 6.56 0zm.364 0a3.645 3.645 0 1 1-7.29 0 3.645 3.645 0 0 1 7.29 0zm-3.848.542l1.027 1.645h.746l-1.079-1.674c.342-.058.603-.19.782-.396.182-.206.274-.47.274-.793 0-.378-.123-.68-.368-.91-.241-.23-.596-.344-1.067-.344h-1.452v4.117h.665V9.585h.472zm.263-.472h-.735V7.684h.735c.276 0 .478.064.606.193.132.124.199.3.199.525 0 .474-.269.711-.805.711z"
            clipRule="evenodd"
            fillRule="evenodd"
        />
    </>,
    { viewBox: '0 0 255 46', width: '255', height: '46', style: { display: 'block' } },
    'DarkLogo',
);
