import React from 'react';
import { Text, Divider as CoreDivider } from '@ovpn-ui/core';
import { colors } from '@ovpn-ui/styles';

import { withBoxStyles } from '@sso/shared/core/Box';

function Divider({ content, ...props }: { content?: string }) {
    return (
        <CoreDivider {...props}>
            {content && (
                <Text
                    style={{ textTransform: 'uppercase' }}
                    color={colors.neutralTextPlaceholder}
                    variant="textXs"
                    weight="medium"
                >
                    {content}
                </Text>
            )}
        </CoreDivider>
    );
}

export default withBoxStyles(Divider);
