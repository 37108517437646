import React from 'react';
import { X } from '@ovpn-ui/icons';

import 'react-toastify/dist/ReactToastify.min.css';

import { StyledToastContainer } from './ToastContainer.styles';

function CloseButton() {
    return <X size="lg" />;
}

function ToastContainer({ className }: { className?: string }) {
    return (
        <StyledToastContainer
            className={className}
            hideProgressBar
            closeButton={<CloseButton />}
            pauseOnHover={false}
            autoClose={4000}
        />
    );
}

export default ToastContainer;
