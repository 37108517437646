import { addListener, Store, TypedAddListener } from '@reduxjs/toolkit';
import { useDispatch, useStore } from 'react-redux';

import type { AppStore } from './configureStore';
import type { RootState } from './reducer';

export type AppDispatch = AppStore['dispatch'];

export type AddAddListener = TypedAddListener<RootState>;

export const useAppDispatch: () => AppDispatch = useDispatch;

export const useAppStore: () => Store<RootState> = useStore;

export const addAppListener = addListener as AddAddListener;
