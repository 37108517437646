import { setAuthToken, unauthorized } from '@ovpn-ui-toolkit/mf-common';
import { BaseQueryApi } from '@reduxjs/toolkit/query/react';
import { noop } from 'lodash';
import {
    checkCookiesEnabled,
    createHttpRequest,
    COOKIES_ERROR,
    clearCookies,
    getCookie,
} from '@ovpn-ui/utils';

import { addSentryBreadcrumb } from '@sso/shared/utils';
import { ApiError } from '@sso/shared/types';

type Method = 'HEAD' | 'GET' | 'DELETE' | 'PATCH' | 'POST' | 'PUT';
type Query = {
    options?: Record<string, any>;
    data?: Record<string, any>;
    method: Method;
    url: string;
};

export default async function baseQuery(
    { data: payload, options, method, url }: Query,
    { dispatch }: BaseQueryApi,
) {
    const onBeforeRequest = (_: string, opts: RequestInit) => {
        addSentryBreadcrumb({
            category: 'api-request',
            data: {
                payload,
                method,
                url,
            },
        });

        if (!checkCookiesEnabled() && opts.method === 'POST') {
            dispatch(setAuthToken());

            throw new Error(COOKIES_ERROR);
        }

        const hubspotutk = getCookie('hubspotutk');

        if (hubspotutk) {
            return {
                options: {
                    ...opts,
                    headers: {
                        'X-OVPN-Hubspotutk': hubspotutk,
                        ...opts.headers,
                    },
                },
            };
        }

        return undefined;
    };

    const onUnauthorized = () => {
        if (window._env_) {
            dispatch(unauthorized());
        } else {
            clearCookies();
            dispatch(setAuthToken());
        }
    };

    const onSuccess = () => {
        dispatch(setAuthToken());
    };

    const fetch = createHttpRequest({
        apiBase: `${window._sso_env_.API_HOST}/sso/api/v1`,
        captureEvent: noop,
        onBeforeRequest,
        onUnauthorized,
        onSuccess,
    })[method.toLowerCase() as Lowercase<Method>];

    try {
        const data = await fetch(url, payload, options);
        return { data: data || null };
    } catch (e) {
        return { error: e as ApiError };
    }
}
