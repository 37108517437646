import { makeAssert } from '@ovpn-ui/utils';
import { omitBy, isNil } from 'lodash';

import { MfaType, ProductType, UserAuthorityTypes } from '@sso/shared/constants';
import { getClientTimeHeaders, unicodeBtoa } from '@sso/shared/utils';
import { apiSlice } from '@sso/shared/store';

const assert = makeAssert('authApi');

export type LoginPayload = {
    product: ProductType;
    captchaToken: string;
    password: string;
    username: string;
    domain?: string;
};

export type LoginResponse = {
    accountRole?: UserAuthorityTypes;
    trustedDeviceAllowed?: boolean;
    mfaConfiguration?: MfaType;
    mfaDetails?: {
        mfaCode: string;
        uri: string;
    };
};

export type GetFlowTokenPayload = {
    username: string;
    password: string;
    domain: string;
};

export type GetFlowTokenResponse = {
    token: string;
};

export const authApi = apiSlice
    .injectEndpoints({
        endpoints: builder => ({
            login: builder.mutation<LoginResponse, LoginPayload>({
                query({ password, captchaToken, username, domain, product }) {
                    assert(!!captchaToken, 'login: Expected a valid captcha token');
                    assert(!!username, 'login: Expected a valid username');
                    assert(!!password, 'login: Expected a valid password');
                    assert(!!product, 'login: Expected a valid product');

                    return {
                        url: '/auth/login',
                        method: 'POST',
                        data: omitBy(
                            {
                                password: unicodeBtoa(password),
                                login: username,
                                captchaToken,
                                product,
                                domain,
                            },
                            isNil,
                        ),
                        options: {
                            headers: getClientTimeHeaders(),
                        },
                    };
                },
            }),
            refresh: builder.mutation<void, void>({
                query() {
                    return {
                        url: '/auth/refresh',
                        method: 'PUT',
                    };
                },
            }),
            logout: builder.mutation<void, void>({
                query() {
                    return {
                        url: '/auth/logout',
                        method: 'DELETE',
                    };
                },
            }),
            getUserActivationToken: builder.mutation<GetFlowTokenResponse, GetFlowTokenPayload>({
                query({ username, domain, password }) {
                    assert(!!username, 'getUserActivationToken: Expected a valid username');
                    assert(!!password, 'getUserActivationToken: Expected a valid password');
                    assert(!!domain, 'getUserActivationToken: Expected a valid domain');

                    return {
                        url: '/members/activation/otp/confirm',
                        method: 'POST',
                        data: {
                            username,
                            password,
                            domain,
                        },
                    };
                },
            }),
            getUserForceResetToken: builder.mutation<GetFlowTokenResponse, GetFlowTokenPayload>({
                query({ username, domain, password }) {
                    assert(!!username, 'getUserForceResetToken: Expected a valid username');
                    assert(!!password, 'getUserForceResetToken: Expected a valid password');
                    assert(!!domain, 'getUserForceResetToken: Expected a valid domain');

                    return {
                        url: '/auth/domain/password/force-reset/confirm-code',
                        method: 'POST',
                        data: {
                            digitCode: password,
                            username,
                            domain,
                        },
                    };
                },
            }),
        }),
    })
    .enhanceEndpoints({
        addTagTypes: ['auth-options', 'profile', 'profile-template'],
        endpoints: {
            logout: {
                invalidatesTags: ['auth-options', 'profile', 'profile-template'],
            },
        },
    });

export const {
    useGetUserActivationTokenMutation,
    useGetUserForceResetTokenMutation,
    useRefreshMutation,
    useLogoutMutation,
    useLoginMutation,
} = authApi;
