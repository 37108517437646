import React from 'react';

import LightIcon from './Icon.light';
import DarkIcon from './Icon.dark';

export type LogoProps = {
    className?: string;
    height?: number;
    width?: number;
    dark?: boolean;
};

function Logo({ className, dark, width = 136, height = 24 }: LogoProps) {
    const Icon = dark ? DarkIcon : LightIcon;

    return (
        <a href={`${window._sso_env_.PRODUCT_HOST}`}>
            <Icon className={className} style={{ width, height }} />
        </a>
    );
}

export default Logo;
