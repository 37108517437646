import React, { useCallback } from 'react';
import { colors } from '@ovpn-ui/styles';

import { BoxOwnProps, Text } from '@sso/shared/core';
import { ProductType } from '@sso/shared/constants';

import { Container } from './ProductSelectCard.styles';

export type ProductSelectCardProps = BoxOwnProps & {
    onSelect: (product: ProductType) => void;
    name: React.ReactNode;
    product: ProductType;
    description: string;
    id: string;
};

function ProductSelectCard({
    description,
    onSelect,
    product,
    name,
    ...props
}: ProductSelectCardProps) {
    const selectProduct = useCallback(() => onSelect(product), [onSelect, product]);

    return (
        <Container onClick={selectProduct} {...props}>
            <Text color={colors.neutralTextTitle} variant="textLg" weight="medium" m={0}>
                {name}
            </Text>
            <Text color={colors.neutralTextSubtitle} variant="textMd">
                {description}
            </Text>
        </Container>
    );
}

export default ProductSelectCard;
