import React from 'react';
import { ArrowLeft } from '@ovpn-ui/icons';
import { colors } from '@ovpn-ui/styles';

import { Text, Anchor, AnchorProps, BoxOwnProps } from '@sso/shared/core';

type NavigationLinkProps = AnchorProps &
    BoxOwnProps & {
        content?: React.ReactNode;
        before?: React.ReactNode;
        after?: React.ReactNode;
        isReturnBack?: boolean;
        returnBack?: boolean;
    };

function NavigationLink({
    underline,
    external,
    disabled,
    children,
    variant,
    onClick,
    target,
    type,
    href,
    to,
    id,

    isReturnBack,
    before,
    after,

    ...boxProps
}: NavigationLinkProps) {
    return (
        <Text color={colors.neutralTextSubtitle} variant="textMd" {...boxProps}>
            {before}
            {before && ' '}
            <Anchor
                underline={underline}
                external={external}
                disabled={disabled}
                variant={variant}
                onClick={onClick}
                target={target}
                type={type}
                href={href}
                to={to}
                id={id}
            >
                {isReturnBack && (
                    <ArrowLeft
                        style={{ margin: '0 4px 0 0', verticalAlign: 'sub' }}
                        strokeWidth={2}
                    />
                )}
                {children}
            </Anchor>
            {after && ' '}
            {after}
        </Text>
    );
}

export default NavigationLink;
