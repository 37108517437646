import styled from 'styled-components';
import { colors } from '@ovpn-ui/styles';

import { Anchor as _Anchor } from '@sso/shared/core';

export const Anchor = styled(_Anchor)`
    color: ${colors.white};

    &:hover {
        color: ${colors.white};
    }
`;
