export function unicodeBtoa(string: string) {
    const codeUnits16 = new Uint16Array(string.length).map((_, i) => string.charCodeAt(i));
    const codeUnits8 = String.fromCharCode(...new Uint8Array(codeUnits16.buffer));
    return btoa(codeUnits8);
}

export function unicodeAtob(string: string) {
    const decoded = atob(string);
    const codeUnits8 = new Uint8Array(decoded.length).map((_, i) => decoded.charCodeAt(i));
    return String.fromCharCode(...new Uint16Array(codeUnits8.buffer));
}
