import { useEffect } from 'react';
import { setAuthToken } from '@ovpn-ui-toolkit/mf-common';
import { AnyAction } from '@reduxjs/toolkit';

import { addAppListener, useAppDispatch } from '@sso/shared/store';
import {
    useRefreshMutation,
    selectDecodedToken,
    setProductParams,
    selectLoggedIn,
} from '@sso/shared/redux';

import { useNavigate } from '../navigation';

const MAX_SAFE_INTEGER = 2 ** 31 - 1;
const MIN_REFRESH_DELAY = 10000;

const isAuthRefresh = (action: AnyAction): action is AnyAction =>
    action.type === setAuthToken.TRIGGER || action.type === setProductParams.type;

export default function useAuthMonitor() {
    const [refresh] = useRefreshMutation();

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    // biome-ignore lint/correctness/useExhaustiveDependencies:
    useEffect(
        () =>
            dispatch(
                addAppListener({
                    matcher: isAuthRefresh,
                    effect: async (_, listenerApi) => {
                        listenerApi.cancelActiveListeners();

                        const loggedIn = selectLoggedIn(listenerApi.getState());

                        if (loggedIn) {
                            const token = selectDecodedToken(listenerApi.getState())!;

                            const expTimestamp = new Date(token.exp * 1000).getTime();
                            const nowTimestamp = new Date().getTime();

                            const refreshDelay = Math.min(
                                (expTimestamp - nowTimestamp) / 2,
                                MAX_SAFE_INTEGER,
                            );

                            await listenerApi.delay(refreshDelay);

                            try {
                                await refresh().unwrap();
                            } catch {
                                if (refreshDelay <= MIN_REFRESH_DELAY) {
                                    navigate({ key: 'logout' });
                                }
                            }
                        }
                    },
                }),
            ) as unknown as () => void,
        [],
    );
}
