import React from 'react';

import { withBoxStyles } from '@sso/shared/core/Box';

import { Sinner as StyledSpinner } from './Spinner.styles';
import { SpinnerProps } from './Spinner.props';

function Spinner({ absolute = false, delayed = false, size = 56, ...props }: SpinnerProps) {
    return <StyledSpinner $absolute={absolute} $delayed={delayed} size={size} {...props} />;
}

export default withBoxStyles(Spinner);
