import React, { useMemo } from 'react';
import { RedirectProps, Redirect, useLocation } from 'react-router-dom';

export default function SearchRedirect({ to, ...props }: RedirectProps) {
    const { search, state } = useLocation();

    const redirect = useMemo(
        () =>
            typeof to === 'object'
                ? {
                      ...to,
                      search,
                      state,
                  }
                : {
                      pathname: to,
                      search,
                      state,
                  },
        [to, search, state],
    );

    return <Redirect {...props} to={redirect} />;
}
