import { errorCodes as codes } from './errorCodes';

export const errorMessages = {
    [codes.UNKNOWN]: 'Unknown error occurred',
    [codes.MAINTENANCE]: 'Service is currently under maintenance',

    [codes.internal.UNKNOWN]: 'Internal error',
    [codes.internal.AUTHENTICATION_ERROR]: 'Unauthorized',
    [codes.internal.AUTHORIZATION_ERROR]: 'Unauthorized',
    [codes.internal.REGISTRATION_NOT_COMPLETED]: 'Registration not completed',
    [codes.internal.REQUEST_VALIDATION_ERROR]: 'Request validation failed',
    [codes.internal.CAPTCHA_VALIDATION_ERROR]: 'reCAPTCHA verification failed',
    [codes.internal.EMAIL_VALIDATION_ERROR]:
        'Email verification failed. Please use a different email address',
    [codes.internal.ACCOUNT_EXISTS]:
        'Active account with this email address already exists. Go ahead and just sign in',
    [codes.internal.ACCOUNT_NOT_EXISTS]: "Account doesn't exist",
    [codes.internal.ACTIVATION_DATA_DOESNT_EXIST]:
        "Activation data doesn't exist. Please try again",
    [codes.internal.PASSWORD_INPUTS_DO_NOT_MATCH]: "Passwords don't match",
    [codes.internal.PASSWORD_INPUTS_MATCH]: 'Password cannot be the same as the previous one',
    [codes.internal.BAD_CREDENTIALS]: 'Email\u200A/\u200APassword does not match',
    [codes.internal.INVALID_RESET_PASSWORD_TOKEN]:
        'Reset password token is expired. Please submit a new request to reset your password',
    [codes.internal.AUTH_INVALID_PASSWORD_VALUE]: 'Wrong old password provided',
    [codes.internal.ENTITY_NOT_FOUND]: 'Something went wrong. Please try again later',
    [codes.internal.EMAIL_RESEND_NOT_ALLOWED_YET]:
        'Resend email not allowed. Please try again later',
    [codes.internal.NO_DIGIT_CODE_PRESENT]:
        'This code is no longer valid. Please request a new code',
    [codes.internal.INCORRECT_DIGIT_CODE]: 'Wrong verification code',
    [codes.internal.ACCOUNT_BANNED]: 'Cannot proceed. Please contact Support',
    [codes.internal.TENANT_NAME_EXISTS]: 'The domain is already used',
    [codes.internal.OWNER_NOT_FOUND]: "Owner with given email doesn't exist",
    [codes.internal.ACCOUNT_CREATION_FAIL]: 'Failed to create an account',
    [codes.internal.ACCOUNT_DELETE_BLOCKED_BY_LICENSE]:
        'Your account cannot be deleted because you have an active license or refundable credit(s)',
    [codes.internal.ACCOUNT_DELETE_BLOCKED_BY_SUBSCRIPTION]:
        'Your account cannot be deleted because you have an active subscription or refundable credit(s)',
    [codes.internal.INVALID_MFA_METHOD]:
        'MFA validation failure. Check your MFA settings and try again.',
    [codes.internal.WRONG_PASSWORD_FORCE_LOGOUT]:
        "You've been logged out because of too many incorrect password attempts",
    [codes.internal.WRONG_PASSWORD]: 'Wrong password',
    [codes.internal.RESET_PASSWORD_CODE_EXPIRED]:
        'Your reset password code has been expired. Please try again',
    [codes.internal.RESET_PASSWORD_TOKEN_EXPIRED]:
        'Your reset password token has been expired. Please try again',
    [codes.internal.MEMBER_RESET_PASSWORD_CODE_EXPIRED]:
        'Your reset password code has been expired. Please try again',
    [codes.internal.MEMBER_RESET_PASSWORD_TOKEN_EXPIRED]:
        'Your reset password token has been expired. Please try again',
    [codes.internal.ACTIVATION_CODE_NOT_ENTERED]: "Activation code wasn't entered",
    [codes.internal.RECENT_PASSWORD_NOT_ALLOWED]:
        'You cannot use a password you have recently used',
    [codes.internal.SAME_PASSWORD_NOT_ALLOWED]: 'Attempt to change password to the same one',
    [codes.internal.BLACKLISTED_EMAIL]: 'Cannot proceed. Please contact Support',
    [codes.internal.PROHIBITED_EMAIL]: 'Cannot proceed. Please contact Support',
    [codes.internal.PASSWORD_FORGOT_NOT_ALLOWED]:
        'Cannot reset password. Please proceed to your IdP',
    [codes.internal.INVALID_CLIENT_TIME]:
        'Seems your device clock is wrong. Please correct the time on your device to proceed',
    [codes.internal.PASSWORD_LOGIN_DISABLED]: 'Email\u200A/\u200APassword does not match',
    [codes.internal.OAUTH_LOGIN_FAIL]:
        "Your Oauth Account isn't associated with OpenVPN, or your credentials don't match. Try again, or sign in with your email and password.",
    [codes.internal.AWS_MARKETPLACE_ERROR]: 'AWS Marketplace interaction failed',
    [codes.internal.VALIDATION_SERVICE_FAIL]: 'Cannot proceed. Please contact Support',
    [codes.internal.RELAY_CONNECTION_FAIL]: "Couldn't establish connection to SSO-Relay service",
    [codes.internal.INVALID_ACTIVATION_TOKEN]: 'Invalid activation data',
    [codes.internal.INVALID_SAML_CONFIGURATION]: 'SAML configuration validation failed',
    [codes.internal.SAML_CONFIGURATION_NOT_FOUND]: 'SAML configuration not found',
    [codes.internal.IDP_UNABLE_TO_READ_METADATA]: 'Unable to read identity provider configuration',
    [codes.internal.SP_METADATA_GENERATION_FAILED]: 'Failed to load service provider metadata',
    [codes.internal.IDP_INVALID_CONFIGURATION]: 'Non valid XML for SAML configuration',
    [codes.internal.LDAP_SERVER_NOT_RESPONDING]: "Couldn't establish connection to LDAP server",
    [codes.internal.OTP_PASSWORD_SETUP_REQUIRED]: 'Password setup required',
    [codes.internal.FORCED_PASSWORD_RESET]: 'Password reset required',

    [codes.internal.BILLING_UNREACHABLE]: 'Cannot create Billing account. Please try again later',
    [codes.internal.INVALID_MEMBER]:
        'Please make sure your Cloud ID and / or username are entered correctly',
    [codes.internal.ACCOUNT_SUSPENDED]: 'This account has been suspended',
    [codes.internal.BAD_MEMBER_CREDENTIALS]: 'Username\u200A/\u200APassword does not match',
    [codes.internal.PROHIBITED_DOMAIN]: 'Domain cannot be used',
    [codes.internal.MEMBER_EXISTS_IN_ACCOUNT]:
        'Activation data for this user already expired. Please contact your administrator for further assistance',

    [codes.integration.X_VERIFY_IO]: 'Failed to perform email validation',
    [codes.integration.GENERIC_INTEGRATION_ERROR]: 'Something went wrong. Please try again later',
} as Record<string, string>;
