import React from 'react';
import { useSelector } from 'react-redux';

import { selectIsRedirecting, selectIsInitialized, selectLoggedIn } from '@sso/shared/redux';
import { isCloudAuth, ls } from '@sso/shared/utils';
import { Spinner } from '@sso/shared/core';

import { Container, Main } from './PublicLayout.styles';
import Footer from './Footer';
import Header from './Header';

function PublicLayout({ children }: { children: React.ReactNode }) {
    const isRedirecting = useSelector(selectIsRedirecting);
    const isInitialized = useSelector(selectIsInitialized);
    const loggedIn = useSelector(selectLoggedIn);

    if (isRedirecting || !isInitialized) {
        return <Spinner absolute />;
    }

    return (
        <Container>
            <Header amazonToken={ls.getAmazonToken()} navigation={!loggedIn && !isCloudAuth()} />
            <Main>{children}</Main>
            <Footer />
        </Container>
    );
}

export default PublicLayout;
