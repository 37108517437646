import { setAuthToken, unauthorized } from '@ovpn-ui-toolkit/mf-common';
import { createSlice, createAction } from '@reduxjs/toolkit';
import { getCookie } from '@ovpn-ui/utils';

import { tokenId } from '@sso/shared/constants';

type AuthState = {
    token: string | undefined;
};

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        token: getCookie(tokenId),
    } as AuthState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(setAuthToken.TRIGGER, state => {
            state.token = getCookie(tokenId);
        });

        builder.addCase(unauthorized.TRIGGER, state => {
            state.token = getCookie(tokenId);
        });
    },
});

// product specific action to handle cases of product token invalidation on mfa config change
export const productTokenInvalidated = createAction('AUTH/PRODUCT_TOKEN_INVALIDATED/TRIGGER');
