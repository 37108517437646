import React, { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { MfaType } from '@sso/shared/constants';

type State = {
    mfaConfiguration?: MfaType;
    resqueMode?: boolean;
    username?: string;
};

export default function useMfaVerificationLayout(): {
    toggleResqueMode: () => void;
    layoutProps: {
        description?: React.ReactNode;
        title: string;
    };
} {
    const { pathname, state } = useLocation<State>();
    const history = useHistory<State>();

    const toggleResqueMode = useCallback(
        () => history.replace(pathname, { ...state, resqueMode: !state.resqueMode }),
        [history, state, pathname],
    );

    const layoutProps = useMemo(() => {
        const { mfaConfiguration, resqueMode, username } = state || {};

        const title = 'Safety is the core principle of our product';

        switch (mfaConfiguration) {
            case MfaType.Totp:
                return resqueMode
                    ? {
                          title: 'Provide Rescue Code',
                          description: (
                              <>
                                  Enter your <b>10-digit rescue code</b> to sign in to your account.
                              </>
                          ),
                      }
                    : {
                          title,
                          description: (
                              <>
                                  Enter a Two-Factor Code from your <b>authentication app</b>.
                              </>
                          ),
                      };
            case MfaType.Email:
                return {
                    title,
                    description: (
                        <>
                            Enter the Two-Factor Code we sent to your email <b>{username}</b>.
                        </>
                    ),
                };
            case MfaType.None:
            default:
                return {
                    title,
                };
        }
    }, [state]);

    return {
        toggleResqueMode,
        layoutProps,
    };
}
