import {
    globalHandlersIntegration,
    extraErrorDataIntegration,
    breadcrumbsIntegration,
    httpContextIntegration,
    makeFetchTransport,
    defaultStackParser,
    dedupeIntegration,
    SeverityLevel,
    BrowserClient,
    Breadcrumb,
    makeMain,
    Hub,
} from '@sentry/browser';

const clientHub = new Hub();

export const Sentry = (callback: (hub: Hub) => void) => {
    if (!process.env.SENTRY_RELEASE) return;

    makeMain(clientHub);
    clientHub.run(callback);
};

export const initSentry = () => {
    Sentry((hub: Hub) => {
        hub.bindClient(
            new BrowserClient({
                dsn: window._sso_env_.SENTRY_DSN,
                release: process.env.SENTRY_RELEASE,
                environment: window._sso_env_.CLUSTER_NAME,

                normalizeDepth: 10,
                maxValueLength: 1000,

                transport: makeFetchTransport,
                stackParser: defaultStackParser,
                integrations: [
                    dedupeIntegration(),
                    breadcrumbsIntegration(),
                    httpContextIntegration(),
                    globalHandlersIntegration({ onunhandledrejection: false }),
                    extraErrorDataIntegration({ depth: 10, captureErrorCause: true }),
                ],
            }),
        );
    });
};

export const captureSentryEvent = (
    type: string,
    value?: string,
    level: SeverityLevel = 'error',
) => {
    Sentry((hub: Hub) => {
        hub.captureEvent({
            level,
            exception: {
                values: [
                    {
                        value,
                        type,
                    },
                ],
            },
        });
    });
};

export const addSentryBreadcrumb = (breadcrumb: Breadcrumb) => {
    Sentry((hub: Hub) => {
        hub.addBreadcrumb({
            level: 'info',
            ...breadcrumb,
        });
    });
};
