import React from 'react';
import styled from 'styled-components';
import { media } from '@ovpn-ui/styles';

export const Container = styled.header<{ className?: string; children: React.ReactNode }>`
    display: flex;
    flex-wrap: wrap;
    gap: 8px 16px;
    align-items: center;
    justify-content: space-between;
    min-width: 100%;
    min-height: 96px;
    padding: 24px 112px;

    ${media.MD`
        padding: 24px 48px;
    `}

    ${media.XS`
        padding: 24px 16px;
    `}
`;
