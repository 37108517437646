import styled from 'styled-components';
import { colors } from '@ovpn-ui/styles';

import { Box } from '@sso/shared/core';

export const Container = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const Ul = styled.ul`
    margin: 12px 0 0;
    padding: 16px 8px 16px 28px;

    list-style: initial;
    border: 1px solid ${colors.neutralBgHover};
    border-radius: 8px;

    li {
        margin: 0 0 4px;

        span {
            display: inline;
        }

        &:last-of-type {
            margin: 0;
        }
    }
`;
