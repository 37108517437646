import React, { forwardRef, Ref } from 'react';
import { Switch as CoreSwitch } from '@ovpn-ui/core';

import { withBoxStyles } from '@sso/shared/core/Box';
import Button from '@sso/shared/core/Button';

import { SwitchProps } from './Switch.props';

const Switch = forwardRef(({ options, ...props }: SwitchProps, ref: Ref<HTMLDivElement>) => (
    <CoreSwitch ref={ref} {...props}>
        {options.map(({ title, id }) => (
            <Button key={id}>{title}</Button>
        ))}
    </CoreSwitch>
));

export default withBoxStyles<SwitchProps, 'input'>(Switch);
