import {
    configureStore as configureStoreOriginal,
    createListenerMiddleware,
} from '@reduxjs/toolkit';

import reducer, { type RootState } from './reducer';
import sentryMiddleware from './sentryMiddleware';
import errorListener from './errorListener';
import apiSlice from './apiSlice';

function configureStore(devToolsName = 'sso') {
    const listenerMiddleware = createListenerMiddleware<RootState>();

    errorListener(listenerMiddleware.startListening);

    return configureStoreOriginal({
        reducer,
        middleware: getDefaultMiddleware =>
            getDefaultMiddleware({ serializableCheck: false })
                .prepend(listenerMiddleware.middleware)
                .concat(apiSlice.middleware)
                .concat(sentryMiddleware),
        devTools: process.env.NODE_ENV === 'development' && {
            shouldHotReload: false,
            name: devToolsName,
        },
    });
}

export type AppStore = ReturnType<typeof configureStore>;

export default configureStore;
