import { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { LocationDescriptor } from 'history';
import { parseSearch } from '@ovpn-ui/utils';
import { toast } from 'react-toastify';

export default function useNotification() {
    const { search } = useLocation();
    const history = useHistory();

    // biome-ignore lint/correctness/useExhaustiveDependencies:
    useEffect(() => {
        const { deleted } = parseSearch(search);

        if (deleted) {
            toast.success('Success! Account deleted');
            history.replace(0 as LocationDescriptor);
        }
    }, []);
}
