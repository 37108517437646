import React from 'react';
import { colors } from '@ovpn-ui/styles';

import { Text } from '@sso/shared/core';

import { Container } from './Footer.styles';

function Footer() {
    const year = new Date().getFullYear();

    return (
        <Container>
            <Text color={colors.neutralTextPlaceholder} variant="textXs" center>
                © {year} OpenVPN Inc. All Rights Reserved
            </Text>
        </Container>
    );
}

export default Footer;
