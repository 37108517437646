import { getTenant } from '@ovpn-ui-toolkit/mf-common';
import { serialize } from '@ovpn-ui/utils';

import { ProductType } from '@sso/shared/constants';

export const isCloudAuth = () => getTenant() === 'auth';

export const getEnvProduct = (match?: ProductType) => {
    const product = window._env_.APP_NAME === 'cloud-ui-root' ? ProductType.Cloud : ProductType.As;

    return match ? match === product : product;
};

export const getEnvProductParams = () => {
    const product = getEnvProduct();
    const domain = getTenant();

    return {
        domain: product === ProductType.Cloud && domain !== 'cloud' ? domain : undefined,
        product: product as ProductType,
    };
};

export function getSSORedirectUrl(path: string) {
    const { product, domain: tenant } = getEnvProductParams();

    const queryParams = {
        return: window.location.origin,
        product,
        tenant,
    };

    return `${window._env_.SSO_HOST}/${path}${serialize(queryParams)}`;
}

export function getClientTimeHeaders() {
    return {
        'X-Timestamp': `${Math.floor(Date.now() / 1000)}`,
    };
}

export function saveLinkAsFile(blob: Blob, name: string) {
    // IE
    if (window.navigator && window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(blob, name);

        return;
    }

    // Chrome on iOS
    if (/CriOS\/[\d]+/.test(navigator.userAgent)) {
        const reader = new FileReader();

        reader.onloadend = () => {
            const a = document.createElement('a');

            a.href = reader.result as string;
            a.style.display = 'none';
            a.download = name;
            document.body.appendChild(a);
            a.click();
            a.parentNode?.removeChild(a);
        };

        reader.readAsDataURL(blob);

        return;
    }

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);

    if (name) {
        link.download = name;
    }

    let clickEvent;

    if (typeof Event === 'function') {
        clickEvent = new MouseEvent('click', {
            cancelable: true,
            bubbles: true,
            view: window,
        });
    } else {
        // IE
        const event = document.createEvent('Event');

        event.initEvent('click', true, true);
        clickEvent = event;
    }

    link.dispatchEvent(clickEvent);
}
