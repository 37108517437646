import { lazy } from 'react';

import { routes, paths } from '@sso/shared/constants';

const PasswordRecoveryFlow = lazy(
    () => import('@sso/password-recovery/containers/PasswordRecoveryFlow'),
);
const SigninFlow = lazy(() => import('@sso/signin/containers/SigninFlow'));
const SignupFlow = lazy(() => import('@sso/signup/containers/SignupFlow'));

const NotFoundPage = lazy(() => import('common/containers/NotFoundPage'));
const LogoutPage = lazy(() => import('common/containers/LogoutPage'));

export default [
    {
        path: paths.password,
        component: PasswordRecoveryFlow,
        guard: {
            notLoggedIn: true,
        },
        exact: false,
    },
    {
        path: paths.signup,
        component: SignupFlow,
        guard: {
            webAuth: false,
        },
        exact: false,
    },
    {
        path: paths.signin,
        component: SigninFlow,
        exact: false,
    },
    {
        path: routes.logout,
        component: LogoutPage,
    },
    {
        path: routes.notFound,
        component: NotFoundPage,
    },
];
