import React from 'react';
import { Redirect } from 'react-router-dom';

import { useGtmAnalytics, useProductParams, useAuthMonitor } from '@sso/shared/hooks';
import { ErrorBoundary, SearchRedirect, GuardedRouter } from '@sso/shared/components';
import { PublicLayout } from '@sso/shared/layouts';
import { routes } from '@sso/shared/constants';

import useGuardedRouter from './useGuardedRouter';
import useNotification from './useNotification';
import ToastContainer from './ToastContainer';
import routeConfig from './App.routes';

function App() {
    useAuthMonitor();
    useProductParams();
    useGuardedRouter();
    useGtmAnalytics();
    useNotification();

    return (
        <ErrorBoundary>
            <PublicLayout>
                <GuardedRouter routes={routeConfig} redirect={routes.notFound} topLevel>
                    <Redirect from="/domain-enrollment" to="/signup/cvpn/domain-enrollment" exact />
                    <SearchRedirect from="/saml-error" to="/signin/cvpn/saml-error" exact />
                    <Redirect from="/" to="/signin" exact />
                </GuardedRouter>
            </PublicLayout>
            <ToastContainer />
        </ErrorBoundary>
    );
}

export default App;
