import React from 'react';
import { colors } from '@ovpn-ui/styles';

import { Anchor, Text } from '@sso/shared/core';

export function SupportLink() {
    return (
        <Text color={colors.neutralTextSubtitle} variant="textMd">
            Need some help?{' '}
            <Anchor href={`${window._sso_env_.SUPPORT_HOST}/hc/en-us`} target="_blank">
                Contact Support
            </Anchor>
        </Text>
    );
}
