import { useEffect, useCallback, useRef } from 'react';
import { usePrevious, useEventListener } from '@ovpn-ui/utils';
import { getTenant } from '@ovpn-ui-toolkit/mf-common';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { selectProduct, selectIsRedirecting } from '@sso/shared/redux';
import { gtmEvents } from '@sso/shared/constants';
import { sendGtmEvent } from '@sso/shared/utils';
import { GtmEventData } from '@sso/shared/types';

import { getAB } from '../useAB';

window.dataLayer = window.dataLayer || [];

export default function useGtmAnalytics() {
    const redirecting = useSelector(selectIsRedirecting);
    const product = useSelector(selectProduct);

    const { pathname } = useLocation();

    const previousPathname = usePrevious(pathname);
    const sessionStarted = useRef(false);

    const sendAnalyticsEvent = useCallback(
        (eventData: GtmEventData) =>
            sendGtmEvent({
                timestamp: new Date().getTime(),
                previousPath: previousPathname,
                path: pathname,
                product,
                ...eventData,
            }),
        [pathname, previousPathname, product],
    );

    // biome-ignore lint/correctness/useExhaustiveDependencies:
    useEffect(() => {
        if (!sessionStarted.current) {
            sendAnalyticsEvent({
                event: gtmEvents.sessionStart,
                appVersion: window._sso_env_.BUILD_VERSION,
                appSubdomain: getTenant(),
            });

            sendGtmEvent({
                event: gtmEvents.abTestingStart,
                group: getAB(),
            });

            sessionStarted.current = true;
        } else {
            sendAnalyticsEvent({ event: gtmEvents.navigation });
        }
    }, [pathname]);

    useEventListener('beforeunload', () => {
        if (redirecting) {
            sendAnalyticsEvent({ event: gtmEvents.productRedirect });
        }

        sendAnalyticsEvent({ event: gtmEvents.sessionEnd });
    });
}
