import React from 'react';

function ErrorSvg() {
    return (
        <svg width="185" height="151" viewBox="0 0 185 151" fill="none">
            <path
                d="M180 129H173V37.2C173 31.6 168.3 27 162.5 27H22.1C16.3 27 11.6 31.6 11.6 37.2V129H4.60001C2.70001 129 1.10001 130.5 1.10001 132.4V139.4C1.10001 145 5.80001 149.6 11.6 149.6H173C178.8 149.6 183.5 145 183.5 139.4V132.4C183.5 130.6 181.9 129 180 129Z"
                fill="#CBDCF8"
            />
            <path
                opacity="0.55"
                d="M166.1 123.3C166.1 126.7 163.4 129.5 160.1 129.5H25C21.7 129.5 19 126.7 19 123.3V40.3C19 37.3 21.4 34.9 24.2 34.9H161C163.9 34.9 166.2 37.3 166.2 40.3V123.3H166.1Z"
                fill="#95B6DF"
            />
            <path
                d="M172.9 129H171.9V130H172.9V129ZM172.9 37.2H173.9H172.9ZM162.4 27V26V27ZM22.1 27V26V27ZM11.5 37.2H10.5H11.5ZM11.5 129V130H12.5V129H11.5ZM1 139.5H0H1ZM11.5 149.7V150.7V149.7ZM172.9 149.7V150.7V149.7ZM183.5 139.5H184.5H183.5ZM183.5 132.4H182.5H183.5ZM180 128H173V130H180V128ZM173.9 129V37.2H171.9V129H173.9ZM173.9 37.2C173.9 31 168.7 26 162.4 26V28C167.7 28 171.9 32.2 171.9 37.2H173.9ZM162.4 26H22.1V28H162.5V26H162.4ZM22.1 26C15.8 26 10.6 31 10.6 37.2H12.6C12.6 32.1 16.8 28 22.1 28V26ZM10.5 37.2V129H12.5V37.2H10.5ZM11.5 128H4.5V130H11.5V128ZM4.5 128C2 128 0 130 0 132.4H2C2 131.1 3.1 130 4.5 130V128ZM0 132.4V139.4H2V132.4H0ZM0 139.5C0 145.7 5.2 150.7 11.5 150.7V148.7C6.2 148.7 2 144.5 2 139.5H0ZM11.5 150.7H172.9V148.7H11.5V150.7ZM172.9 150.7C179.2 150.7 184.4 145.7 184.4 139.5H182.4C182.4 144.6 178.2 148.7 172.9 148.7V150.7ZM184.5 139.5V132.5H182.5V139.5H184.5ZM184.5 132.4C184.5 129.9 182.5 128 180 128V130C181.4 130 182.5 131.1 182.5 132.4H184.5Z"
                fill="#7B9FDB"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.60001 134.1H179.2V145.8H6.60001V134.1Z"
                fill="#CBDCF8"
            />
            <path
                d="M176.6 139.5C176.6 141.4 175 142.9 173.1 142.9H11.7C9.8 142.9 8.2 141.4 8.2 139.5V135.9H176.6V139.5Z"
                fill="#E9F2FA"
                stroke="#7B9FDB"
                strokeWidth="2"
            />
            <path
                d="M19.4 123.5C19.4 126.8 22.1 129.5 25.4 129.5H159.5C162.8 129.5 165.5 126.8 165.5 123.5V40.7C165.5 37.8 163.1 35.4 160.3 35.4H123.1C122.2 35.4 121.4 35.7 120.9 36.3L111.9 47.7H19.2L19.4 123.5Z"
                fill="#F1F6FF"
            />
            <path
                d="M22 129.3H162.8C164.5 129.3 165.8 128 165.8 126.3V54.8L19 54.6V126.4C19 128 20.3 129.3 22 129.3Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M108.2 105.9C116.6 97.5 116.6 83.8 108.2 75.3C99.8 66.9 86 66.9 77.6 75.3C69.2 83.7 69.2 97.4 77.6 105.9C86 114.3 99.7 114.3 108.2 105.9Z"
                fill="#7B9FDB"
            />
            <path d="M84.6 82.3L101.2 98.9" stroke="white" strokeWidth="4" strokeLinecap="round" />
            <path d="M100.5 82.9L84.5 99" stroke="white" strokeWidth="4" strokeLinecap="round" />
            <path d="M122.3 35.3L112 47.6H18.7" stroke="#7B9FDB" strokeWidth="2" fill="none" />
            <path
                d="M166.1 123.3C166.1 126.7 163.4 129.5 160.1 129.5H25C21.7 129.5 19 126.7 19 123.3V40.3C19 37.3 21.4 34.9 24.2 34.9H161C163.9 34.9 166.2 37.3 166.2 40.3V123.3H166.1Z"
                stroke="#7B9FDB"
                strokeWidth="2"
                fill="none"
            />
            <path d="M19.3 54H165.8" stroke="#7B9FDB" strokeWidth="2" />
            <path
                d="M29.5 43.7H27.6C26.2 43.7 25.1 42.6 25.1 41.2C25.1 39.8 26.2 38.7 27.6 38.7H29.5C30.9 38.7 32 39.8 32 41.2C31.9 42.6 30.8 43.7 29.5 43.7Z"
                fill="#CBDCF8"
                stroke="#7B9FDB"
                strokeWidth="2"
            />
            <path
                d="M40.8 43.7H38.9C37.5 43.7 36.4 42.6 36.4 41.2C36.4 39.8 37.5 38.7 38.9 38.7H40.8C42.2 38.7 43.3 39.8 43.3 41.2C43.2 42.6 42.1 43.7 40.8 43.7Z"
                fill="#CBDCF8"
                stroke="#7B9FDB"
                strokeWidth="2"
            />
            <path
                d="M52.1 43.7H50.2C48.8 43.7 47.7 42.6 47.7 41.2C47.7 39.8 48.8 38.7 50.2 38.7H52.1C53.5 38.7 54.6 39.8 54.6 41.2C54.6 42.6 53.5 43.7 52.1 43.7Z"
                fill="#CBDCF8"
                stroke="#7B9FDB"
                strokeWidth="2"
            />
        </svg>
    );
}

export default ErrorSvg;
