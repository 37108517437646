import { createSlice } from '@reduxjs/toolkit';

import { AnyRecord, ModalType } from '@sso/shared/types';

type ModalState = {
    config: AnyRecord | undefined;
    type: ModalType | undefined;
};

export const modalSlice = createSlice({
    name: 'modal',
    initialState: {
        config: undefined,
        type: undefined,
    } as ModalState,
    reducers: {
        openModal: (state, { payload }) => {
            state.config = payload.config;
            state.type = payload.type;
        },
        closeModal: state => {
            state.config = undefined;
            state.type = undefined;
        },
    },
});

export const { openModal, closeModal } = modalSlice.actions;
