import { useSelector } from 'react-redux';
import { useMemo } from 'react';

import { Route, RouteKey } from '@sso/shared/constants';
import { selectRouteParams } from '@sso/shared/redux';
import { AnyRecord, Params } from '@sso/shared/types';
import { getKeyPath } from '@sso/shared/utils';

export type Payload = {
    fallback?: Route | Route[];
    state?: AnyRecord;
    params?: Params;
    key?: RouteKey;
};

export default function useKeyPath({ fallback, params, state, key }: Payload = {}) {
    const routeParams = useSelector(selectRouteParams);

    return useMemo(() => {
        const pathname = getKeyPath(key, { ...routeParams, ...params }, fallback);

        return state ? { state, pathname } : pathname;
    }, [key, params, fallback, state, routeParams]);
}
