import { makeAssert } from '@ovpn-ui/utils';

import { ProductType } from '@sso/shared/constants';
import type { Template } from '@sso/shared/forms';
import { AnyRecord } from '@sso/shared/types';
import { apiSlice } from '@sso/shared/store';

const assert = makeAssert('profileApi');

export const profileApi = apiSlice
    .injectEndpoints({
        endpoints: builder => ({
            getProfileTemplate: builder.query<Template, void>({
                transformErrorResponse: () => null,
                query() {
                    return {
                        url: '/profile/profile-template',
                        method: 'GET',
                    };
                },
            }),
            getProfile: builder.query<AnyRecord, void>({
                transformResponse: response => response?.profileData,
                query() {
                    return {
                        url: '/profile',
                        method: 'GET',
                    };
                },
            }),
            setProfile: builder.mutation<AnyRecord, { profile: AnyRecord; product: ProductType }>({
                transformResponse: response => response?.profileData,
                query({ profile, product }) {
                    assert(!!profile, 'setProfile: Expected a valid profile');
                    assert(!!product, 'setProfile: Expected a valid product');

                    return {
                        url: '/profile',
                        method: 'PUT',
                        data: {
                            profileType: product,
                            profileData: profile,
                        },
                    };
                },
                onQueryStarted(_, { queryFulfilled, dispatch }) {
                    queryFulfilled.then(({ data }) =>
                        dispatch(
                            profileApi.util.updateQueryData('getProfile', undefined, () => data),
                        ),
                    );
                },
            }),
            setProduct: builder.mutation<void, ProductType>({
                query(product) {
                    assert(!!product, 'setProduct: Expected a valid product');

                    return {
                        url: '/profile/product-type',
                        method: 'POST',
                        data: {
                            product,
                        },
                    };
                },
            }),
        }),
    })
    .enhanceEndpoints({
        addTagTypes: ['profile', 'profile-template'],
        endpoints: {
            getProfileTemplate: {
                providesTags: ['profile-template'],
            },
            getProfile: {
                providesTags: ['profile'],
            },
        },
    });

export const {
    useGetProfileTemplateQuery,
    useSetProfileMutation,
    useSetProductMutation,
    useGetProfileQuery,
} = profileApi;
