import React, { useCallback, useState } from 'react';
import { colors } from '@ovpn-ui/styles';

import { Text, Anchor } from '@sso/shared/core';

import { Container, Ul } from './EmailVerificationLink.styles';

export type EmailVerificationLinkProps<FormValues> = {
    onFormSubmit: (payload: FormValues) => Promise<any>;
    defaultValues?: Partial<FormValues>;
    isLoading: boolean;
    code?: boolean;
};

function EmailVerificationLink<FormValues extends object>({
    defaultValues,
    onFormSubmit,
    code = false,
    isLoading,
}: EmailVerificationLinkProps<FormValues>) {
    const [isExpanded, setExpanded] = useState(false);

    const expand = useCallback(() => setExpanded(true), []);

    const resendEmail = useCallback(
        () => onFormSubmit(defaultValues as Required<FormValues>),
        [onFormSubmit, defaultValues],
    );

    return (
        <Container mt={32}>
            <Text color={colors.neutralTextSubtitle} variant="textMd" as="p" m={0} center>
                No {code ? 'code' : 'email'}?{' '}
                <Anchor onClick={resendEmail} id="request-new-email-link" disabled={isLoading}>
                    Resend
                </Anchor>
            </Text>
            <Text color={colors.neutralTextSubtitle} variant="textMd" as="p" m={0} center>
                Still have problems?{' '}
                <Anchor id="still-have-problems-link" onClick={expand} disabled={isExpanded}>
                    Click Here
                </Anchor>
            </Text>
            {isExpanded && (
                <Ul>
                    <li>
                        <Text color={colors.neutralTextSubtitle} variant="textSm">
                            Check that you entered the correct email address.
                        </Text>
                    </li>
                    <li>
                        <Text color={colors.neutralTextSubtitle} variant="textSm">
                            Verify your spam folder.
                        </Text>
                    </li>
                    <li>
                        <Text color={colors.neutralTextSubtitle} variant="textSm">
                            If you need help to restore access to your account, please contact our{' '}
                            <Anchor
                                href={`${window._sso_env_.SUPPORT_HOST}/hc/en-us`}
                                target="_blank"
                            >
                                Support
                            </Anchor>
                            .
                        </Text>
                    </li>
                </Ul>
            )}
        </Container>
    );
}

export default EmailVerificationLink;
