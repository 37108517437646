import React from 'react';
import { colors } from '@ovpn-ui/styles';

import { Text, Anchor, BoxOwnProps } from '@sso/shared/core';

type TermsLinkProps = BoxOwnProps & {
    children?: React.ReactNode;
};

function TermsLink({ children, ...props }: TermsLinkProps) {
    return (
        <Text color={colors.neutralTextSubtitle} variant="textMd" as="p" m={0} {...props}>
            {children || 'You agree to the'}{' '}
            <Anchor href={`${window._sso_env_.PRODUCT_HOST}/legal`} target="_blank">
                Terms of Use
            </Anchor>{' '}
            and{' '}
            <Anchor href={`${window._sso_env_.PRODUCT_HOST}/privacy-policy`} target="_blank">
                Privacy Policy
            </Anchor>
            .
        </Text>
    );
}

export default TermsLink;
