import React from 'react';

import { Anchor } from './SupportToast.styles';

function SupportToast({ children }: { children?: React.ReactNode }) {
    return (
        <>
            {children || 'Cannot proceed. Please contact'}{' '}
            <Anchor href={`${window._sso_env_.SUPPORT_HOST}/hc/en-us`} target="_blank">
                Support
            </Anchor>
        </>
    );
}

export default SupportToast;
