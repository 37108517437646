import { breakPoints } from '@ovpn-ui/styles';

export const tokenId = 'sso_token';

export const codePlaceholder10 = '0000-00-0000';
export const codePlaceholder6 = '000-000';
export const codePattern10 = '          ';
export const codePattern6 = '   -   ';

export const iosMarketLink =
    'https://apps.apple.com/us/app/google-authenticator/id388497605' as const;

export const androidMarketLink =
    'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2' as const;

export const SidebarTheme = {
    mobileBreakpoint: breakPoints.SM,
    tabletBreakpoint: breakPoints.MD,
} as const;
