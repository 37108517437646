/**
 * Custom error that represents any non-back-end related error while resolving captcha on UI.
 * Useful to distinguish between Error and CaptchaError.
 */
export default class CaptchaError extends Error {
    type: string;

    constructor(message: string | Error = 'Captcha failure') {
        super(message.toString());

        Object.setPrototypeOf(this, CaptchaError.prototype);

        if (message instanceof Error && message.stack) {
            this.stack = message.stack;
        }

        this.type = 'captcha';
    }
}
