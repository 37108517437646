import { isTrue, serialize } from '@ovpn-ui/utils';

import { captureSentryEvent } from '@sso/shared/utils';
import { CaptchaKind } from '@sso/shared/constants';
import { AnyRecord } from '@sso/shared/types';

import { Mode, RecaptchaExecutor, TurnstileExecutor } from './Captcha.props';
import { captchaErrorMessage, Modes } from './Captcha.constants';
import CaptchaError from './Captcha.error';

const ENTERPRISE_RECAPTCHA_SCRIPT_URL = 'https://www.google.com/recaptcha/enterprise.js';
const TURNSTILE_SCRIPT_URL = 'https://challenges.cloudflare.com/turnstile/v0/api.js';

type CaptchaSrcUrl = typeof ENTERPRISE_RECAPTCHA_SCRIPT_URL | typeof TURNSTILE_SCRIPT_URL;

export const captchaSentryEvent = (mode: Mode, e: CaptchaError) =>
    captureSentryEvent(captchaErrorMessage, `Mode: ${mode} | ${e.toString()}`, 'log');

export function getCaptchaMode(attempt: number) {
    const captchaDisabled = isTrue(window._sso_env_.V4CAPTCHA, true);

    if (captchaDisabled) {
        return Modes.Disabled;
    }

    if (window.captchaResponse) {
        return Modes.Predefined;
    }

    switch (attempt) {
        case 3:
            return Modes.RecaptchaCheckbox;
        case 2:
            return Modes.TurnstileInvisible;
        case 1:
        default:
            return Modes.RecaptchaInvisible;
    }
}

function loadScript(src: CaptchaSrcUrl, params?: AnyRecord, _attributes: AnyRecord = {}) {
    return new Promise<any>((resolve, reject) => {
        try {
            const container = document.getElementsByTagName('head')[0] || document.documentElement;
            const script = document.createElement('script');

            script.addEventListener('load', resolve);
            script.addEventListener('error', reject);

            const attributes = {
                src: `${src}${serialize(params)}`,
                type: 'text/javascript',

                ..._attributes,
            };

            for (const [key, value] of Object.entries(attributes)) {
                script.setAttribute(key, `${value}`);
            }

            container.appendChild(script);
        } catch (e) {
            reject(e);
        }
    });
}

export function loadCaptchaScript(mode: Mode) {
    return new Promise<void>((resolve, reject) => {
        switch (mode) {
            case Modes.TurnstileInvisible:
                if (window.turnstile) {
                    resolve();
                    break;
                }

                loadScript(TURNSTILE_SCRIPT_URL, { render: 'explicit' })
                    .then(() => resolve())
                    .catch(e => reject(e));
                break;

            case Modes.RecaptchaInvisible:
            case Modes.RecaptchaCheckbox:
                if (window.grecaptcha?.enterprise) {
                    resolve();
                    break;
                }

                loadScript(
                    ENTERPRISE_RECAPTCHA_SCRIPT_URL,
                    {
                        render: window._sso_env_.INV_CAPTCHA,
                        hl: 'en',
                    },
                    {
                        async: true,
                        defer: true,
                    },
                )
                    .then(() => window.grecaptcha?.enterprise?.ready(resolve))
                    .catch(e => reject(e));
                break;

            case Modes.Predefined:
            case Modes.Disabled:
                resolve();
                break;

            default:
                reject(new CaptchaError('Unsupported captcha mode'));
        }
    });
}

export function executeCaptcha(
    mode: Mode,
    action: string,
    recaptcha?: RecaptchaExecutor,
    turnstile?: TurnstileExecutor,
) {
    return new Promise<string | undefined>((resolve, reject) => {
        switch (mode) {
            case Modes.Disabled:
                resolve(Math.random().toString(36).substr(2));
                break;

            case Modes.Predefined:
                resolve(window.captchaResponse);
                break;

            case Modes.TurnstileInvisible:
                resolve(turnstile?.execute(action));
                break;

            case Modes.RecaptchaCheckbox:
                resolve(recaptcha?.execute(action));
                break;

            case Modes.RecaptchaInvisible:
                resolve(
                    window.grecaptcha.enterprise.execute(window._sso_env_.INV_CAPTCHA, { action }),
                );
                break;

            default:
                reject(new CaptchaError('Unsupported captcha mode'));
        }
    });
}

export function formatCaptchaResponse(mode: Mode, token: string) {
    switch (mode) {
        case Modes.TurnstileInvisible:
            return `${CaptchaKind.CFT_INV}:${token}`;

        case Modes.RecaptchaCheckbox:
            return `${CaptchaKind.EGRC_CB}:${token}`;

        case Modes.RecaptchaInvisible:
            return `${CaptchaKind.EGRC_INV}:${token}`;

        case Modes.Predefined:
        case Modes.Disabled:
        default:
            return token;
    }
}
