import React from 'react';
import { AccountGoogle } from '@ovpn-ui/icons';

import { Button, ButtonOwnProps } from '@sso/shared/core';

function GoogleButton({ children, ...props }: ButtonOwnProps) {
    return (
        <Button
            data-testid="google-signin-button"
            style={{ textTransform: 'none' }}
            variant="ghostBlue"
            {...props}
        >
            <AccountGoogle style={{ margin: '0 8px 0 0' }} />
            {children}
        </Button>
    );
}

export default GoogleButton;
