import React, { useCallback } from 'react';
import { Lifebuoy } from '@ovpn-ui/icons';

import { ProductType, ProductName } from '@sso/shared/constants';
import { Anchor } from '@sso/shared/core';

import ProductSelectCard from './ProductSelectCard';

function ProductSelect({ onSelect }: { onSelect: (product: ProductType) => void }) {
    const handleSupportSelect = useCallback(() => onSelect(ProductType.Support), [onSelect]);

    return (
        <>
            <ProductSelectCard
                description="Connect private networks, devices, and servers to build a secure,
                    virtualized modern network. Comes with 3 Free Connections."
                name={
                    <>
                        <span>Cloud</span>
                        <span>Connexa™</span>
                    </>
                }
                id="cloud-product-select-card"
                product={ProductType.Cloud}
                onSelect={onSelect}
                mb={32}
            />
            <ProductSelectCard
                description="A self-hosted VPN server solution that is installed on a
                    private network. Comes with 2 Free Connections."
                name={ProductName[ProductType.As]}
                id="as-product-select-card"
                product={ProductType.As}
                onSelect={onSelect}
                mb={32}
            />
            <Anchor underline={false} onClick={handleSupportSelect} id="support-link" m="0 auto">
                <Lifebuoy style={{ margin: '0 4px 0 0', verticalAlign: 'sub' }} />
                Contact Support
            </Anchor>
        </>
    );
}

export default ProductSelect;
