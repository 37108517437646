import { createSlice } from '@reduxjs/toolkit';

import { ProductType } from '@sso/shared/constants';
import { AnyRecord } from '@sso/shared/types';
import { ls } from '@sso/shared/utils';

type ProductState = {
    productParams: AnyRecord | undefined;
    product: ProductType | undefined;
    domain: string | undefined;
    isInitialized: boolean;
    isRedirecting: boolean;
    isRestrained: boolean;
};

export const productSlice = createSlice({
    name: 'product',
    initialState: {
        product: undefined,
        domain: undefined,
        productParams: {},
        isInitialized: false,
        isRedirecting: false,
        isRestrained: false,
    } as ProductState,
    reducers: {
        setProduct: (state, { payload }) => {
            const product = payload?.toUpperCase();

            state.product = product;

            if (product !== ProductType.Support) {
                ls.setProduct(product);
            }
        },
        setDomain: (state, { payload }) => {
            state.domain = payload;

            ls.setDomain(payload);
        },
        setProductParams: (state, { payload = {} }) => {
            state.productParams = payload;
            state.isInitialized = true;

            ls.setProductParams(payload);
        },
        restrainProductRedirect: (state, { payload }) => {
            state.isRestrained = payload;
        },
        navigateProduct: state => {
            state.isRedirecting = true;
        },
    },
});

export const { restrainProductRedirect, navigateProduct, setProductParams, setProduct, setDomain } =
    productSlice.actions;
