import React, { useImperativeHandle, useCallback, forwardRef, useEffect, useRef } from 'react';

import { ExecutorProps, ExecutorPromise } from './Captcha.props';
import { Container } from './Captcha.styles';
import CaptchaError from './Captcha.error';

const Turnstile = forwardRef(({ sitekey }: ExecutorProps, ref) => {
    const executePromiseRef = useRef<ExecutorPromise | null>();

    const containerRef = useRef<HTMLDivElement>(null);
    const instanceIdRef = useRef<string>();
    const sitekeyRef = useRef<string>();

    const handleReset = useCallback(() => {
        try {
            window.turnstile?.reset(instanceIdRef.current);
        } catch {
            /* ignored */
        }

        instanceIdRef.current = undefined;
        executePromiseRef.current = null;
    }, []);

    const handleSuccess = useCallback(
        response => {
            executePromiseRef.current?.resolve?.(response);
            handleReset();
        },
        [handleReset],
    );

    const handleFailure = useCallback(
        (error?) => {
            executePromiseRef.current?.reject?.(error);
            handleReset();
        },
        [handleReset],
    );

    const handleRemove = useCallback(() => {
        try {
            window.turnstile?.remove(instanceIdRef.current);
        } catch {
            /* ignored */
        }

        instanceIdRef.current = undefined;
        executePromiseRef.current = null;
    }, []);

    const render = useCallback(
        (_sitekey: string) =>
            new Promise((resolve, reject) => {
                try {
                    const key = _sitekey || sitekey;

                    if (!instanceIdRef.current || key !== sitekeyRef.current) {
                        if (window.turnstile && key) {
                            const options = {
                                'expired-callback': handleFailure,
                                'error-callback': handleFailure,
                                callback: handleSuccess,
                                execution: 'execute' as const,
                                language: 'en' as const,
                                sitekey: key,
                            };

                            instanceIdRef.current = window.turnstile?.render(
                                containerRef.current as HTMLElement,
                                options,
                            );

                            sitekeyRef.current = key;

                            resolve(instanceIdRef.current);
                        } else {
                            const error = new CaptchaError('Turnstile render script missing');
                            reject(error);
                        }
                    } else {
                        resolve(instanceIdRef.current);
                    }
                } catch {
                    const error = new CaptchaError('Turnstile render error');
                    reject(error);
                }
            }),
        [handleFailure, handleSuccess, sitekey],
    );

    const execute = useCallback(
        (action = 'generic') =>
            new Promise((resolve, reject) => {
                try {
                    executePromiseRef.current = { resolve, reject };

                    if (instanceIdRef.current && sitekeyRef.current) {
                        window.turnstile?.execute(instanceIdRef.current, {
                            action: action.substring(0, 32),
                            sitekey: sitekeyRef.current,
                        });
                    } else {
                        const error = new CaptchaError('Turnstile execute undefined instance id');
                        reject(error);
                    }
                } catch {
                    const error = new CaptchaError('Turnstile execute error');
                    reject(error);
                }
            }),
        [],
    );

    useImperativeHandle(
        ref,
        () => ({
            execute: (action: string, _sitekey: string) =>
                render(_sitekey).then(() => execute(action)),
        }),
        [execute, render],
    );

    useEffect(() => handleRemove, [handleRemove]);

    return <Container ref={containerRef} />;
});

export default Turnstile;
