import React from 'react';
import { Anchor as CoreAnchor, Link } from '@ovpn-ui/core';

import { withBoxStyles } from '@sso/shared/core/Box';

import { AnchorProps } from './Anchor.props';

const styles = {
    style: {
        textDecoration: 'none',
    },
};

function Anchor({ underline = true, to, ...props }: AnchorProps) {
    return to ? (
        <Link {...(!underline && styles)} {...props} to={to} />
    ) : (
        <CoreAnchor {...(!underline && styles)} {...props} />
    );
}

export default withBoxStyles<AnchorProps, 'a'>(Anchor);
