import React from 'react';
import { colors } from '@ovpn-ui/styles';

import { Spinner, Text } from '@sso/shared/core';

import { Container } from './PublicPageLayout.styles';

type PublicPageLayoutProps = {
    description?: React.ReactNode;
    children?: React.ReactNode;
    header?: React.ReactNode;
    title?: React.ReactNode;
    isLoading?: boolean;
    className?: string;
};

function PublicPageLayout({
    description,
    className,
    isLoading,
    children,
    header,
    title,
}: PublicPageLayoutProps) {
    if (isLoading) {
        return <Spinner absolute />;
    }

    return (
        <Container className={className}>
            {header}
            {title && (
                <Text
                    color={colors.neutralTextBody}
                    mb={description ? 4 : 32}
                    variant="displayMd"
                    weight="semiBold"
                >
                    {title}
                </Text>
            )}
            {description && (
                <Text color={colors.neutralTextSubtitle} variant="textMd" mb={32} mt={0} as="p">
                    {description}
                </Text>
            )}
            {children}
        </Container>
    );
}

export default PublicPageLayout;
