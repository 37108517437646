import { useEffect } from 'react';

import { useProductNavigate, useNavigate } from '@sso/shared/hooks';
import { addAppListener, useAppDispatch } from '@sso/shared/store';
import { accessDenied } from '@sso/shared/components';

export default function useGuardedRouter() {
    const productNavigate = useProductNavigate();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(
        () =>
            dispatch(
                addAppListener({
                    actionCreator: accessDenied,
                    effect: ({ payload: { access } }) => {
                        if (typeof access.loggedIn === 'boolean' && !access.loggedIn) {
                            navigate({ key: 'signin', replace: true });
                            return;
                        }

                        if (typeof access.permissions === 'boolean' && !access.permissions) {
                            navigate({ key: 'notFound', replace: true });
                            return;
                        }

                        if (typeof access.notLoggedIn === 'boolean' && !access.notLoggedIn) {
                            productNavigate();
                        }
                    },
                }),
            ) as unknown as () => void,
        [dispatch, navigate, productNavigate],
    );
}
