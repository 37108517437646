import React from 'react';
import { useSelector } from 'react-redux';
import { colors } from '@ovpn-ui/styles';

import { selectHasUsedCreds } from '@sso/shared/redux';
import { Anchor, Text } from '@sso/shared/core';
import { useKeyPath } from '@sso/shared/hooks';

export function SigninLink() {
    const hasUsedCreds = useSelector(selectHasUsedCreds);

    const signinPath = useKeyPath({ key: hasUsedCreds ? 'signinPrevCreds' : 'signin' });

    return (
        <Text color={colors.neutralTextSubtitle} variant="textMd">
            Already have an account? <Anchor to={signinPath}>Sign In</Anchor>
        </Text>
    );
}
