import { useCallback, useMemo } from 'react';

import { selectProduct, selectUserData } from '@sso/shared/redux';
import { gtmEvents } from '@sso/shared/constants';
import { GtmEventData } from '@sso/shared/types';
import { useAppStore } from '@sso/shared/store';
import * as utils from '@sso/shared/utils/gtm';

window.dataLayer = window.dataLayer || [];

export default function useGtm() {
    const store = useAppStore();

    const sendAuthEvent = useCallback(
        (eventData: GtmEventData) => {
            const userData = selectUserData(store.getState());
            const product = selectProduct(store.getState());

            utils.sendGtmAuthEvent(eventData, userData, product);
        },
        [store],
    );

    const sendOauthEvent = useCallback(
        (eventData: GtmEventData) => {
            const userData = selectUserData(store.getState());
            const product = selectProduct(store.getState());

            utils.sendGtmOauthEvent(eventData, userData, product);
        },
        [store],
    );

    const sendSignupEvent = useCallback(
        (eventData: GtmEventData) => {
            const userData = selectUserData(store.getState());

            utils.sendGtmSignupEvent(eventData, userData);
        },
        [store],
    );

    return useMemo(
        () => ({
            events: gtmEvents,
            sendSignupEvent,
            sendOauthEvent,
            sendAuthEvent,
        }),
        [sendAuthEvent, sendOauthEvent, sendSignupEvent],
    );
}
