import { makeAssert, serialize } from '@ovpn-ui/utils';
import { isEmpty } from 'lodash';

import { OwnerAuthType, ProductType } from '@sso/shared/constants';
import { getClientTimeHeaders } from '@sso/shared/utils';
import { apiSlice } from '@sso/shared/store';

const assert = makeAssert('oauthApi');

export type GetOauthLoginUrlPayload = {
    provider: OwnerAuthType;
    redirectUri: string;
    state?: ProductType;
};

export type LoginOauthPayload = {
    registrationAttributes?: Record<string, string>;
    firstPromoterRefId?: string;
    provider: OwnerAuthType;
    redirectUri: string;
    product: string;
    code: string;
    doSignUp?: boolean;
    domain?: string;
    ip?: string;
};

export const oauthApi = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getOauthLoginUrl: builder.mutation<string, GetOauthLoginUrlPayload>({
            transformResponse: response => response?.url,
            query({ redirectUri, state, provider }) {
                assert(!!redirectUri, 'getOauthLoginUrl: Expected a valid redirectUri');
                assert(!!provider, 'getOauthLoginUrl: Expected a valid provider');

                return {
                    url: '/oauth',
                    method: 'GET',
                    data: {
                        redirectUri,
                        provider,
                        state,
                    },
                };
            },
        }),
        loginOauth: builder.mutation<{ registrationType: string }, LoginOauthPayload>({
            query({
                registrationAttributes,
                firstPromoterRefId,
                doSignUp = false,
                redirectUri,
                provider,
                product,
                domain,
                code,
                ip,
            }) {
                assert(!!redirectUri, 'loginOauth: Expected a valid redirectUri');
                assert(!!provider, 'loginOauth: Expected a valid provider');
                assert(!!product, 'loginOauth: Expected a valid product');
                assert(!!code, 'loginOauth: Expected a valid code');

                return {
                    url: `/oauth/callback${serialize({ provider })}`,
                    method: 'POST',
                    data: {
                        ...(!isEmpty(registrationAttributes) && { registrationAttributes }),
                        ...(firstPromoterRefId && { firstPromoterRefId }),
                        ...(domain && { domain }),
                        redirectUri,
                        doSignUp,
                        product,
                        code,
                        ip,
                    },
                    options: {
                        headers: getClientTimeHeaders(),
                    },
                };
            },
        }),
    }),
});

export const { useGetOauthLoginUrlMutation, useLoginOauthMutation } = oauthApi;
