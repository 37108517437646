import React from 'react';

import { silentErrorCodes, errorCodes, CaptchaKind } from '@sso/shared/constants';
import SupportToast from '@sso/shared/components/SupportToast';
import { ApiError } from '@sso/shared/types';

export function isPrimaryCaptchaFailure(error: ApiError) {
    return (
        errorCodes.internal.CAPTCHA_VALIDATION_ERROR === error.errorCode &&
        error.fieldErrors &&
        (Reflect.has(error.fieldErrors, CaptchaKind.EGRC_INV) ||
            Reflect.has(error.fieldErrors, CaptchaKind.CFT_INV))
    );
}

export function isErrorIgnored(error: ApiError) {
    return isPrimaryCaptchaFailure(error) || silentErrorCodes.includes(error.errorCode);
}

export function getDefaultErrorMessage(error: ApiError) {
    return ['blue', 'green'].includes(window._sso_env_.CLUSTER_NAME) ? (
        <SupportToast>Something went wrong. Please try a minute later or contact</SupportToast>
    ) : (
        `Unexpected error code received: ${error.errorCode}`
    );
}

export function getMessageFromDetails(error: ApiError, key: string) {
    const errorsArray = error?.fieldErrors?.[key] || [];

    if (Array.isArray(errorsArray) && typeof errorsArray[0] === 'string') {
        return errorsArray[0];
    }

    return undefined;
}
