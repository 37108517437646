import { useState } from 'react';
import { storeFactory } from '@ovpn-ui/utils';

import { ls } from '@sso/shared/utils';

const AB_GROUP_KEY = 'ab-group';
const AB_BREAKPOINT = 50;

const abGroups = {
    a: 'A',
    b: 'B',
};

const { set: setABGroup, get: getABGroup } = storeFactory(AB_GROUP_KEY, ls.PREFIX);

export function getAB() {
    let abGroup = getABGroup();

    if (!abGroup) {
        abGroup = Math.floor(Math.random() * 100) < AB_BREAKPOINT ? abGroups.a : abGroups.b;
        setABGroup(abGroup);
    }

    return abGroup.toUpperCase();
}

export default function useAB() {
    const [abGroup] = useState(getAB());
    return abGroup === abGroups.a;
}
