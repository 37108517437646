import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import { colors, media } from '@ovpn-ui/styles';

export const StyledToastContainer = styled(ToastContainer)`
    &.Toastify {
        &__toast-container {
            top: 24px;
            z-index: 1350;

            width: auto;
            max-width: 400px;
            padding: 0;

            &--top-right {
                right: 0;
            }

            ${media.SM`
                top: 165px;

                width: 100%;
                max-width: 100%;
            `}
        }
    }

    .Toastify__toast {
        min-height: auto;
        margin-bottom: 8px;
        padding: 10px;

        color: ${colors.neutralWhite};
        font-weight: 500;
        font-size: 16px;
        font-family: Inter, sans-serif;
        line-height: 24px; /* 150% */
        white-space: pre-line;
        word-break: break-word;

        border-radius: 8px 0 0 8px;
        box-shadow: 0 2px 4px -2px rgba(16, 24, 40, 0.06), 0 4px 8px -2px rgba(16, 24, 40, 0.1);

        .icon-tabler-x {
            flex-shrink: 0;
            margin: 6px 6px 0 24px;
        }

        .Toastify__toast-body .icon-tabler {
            flex-shrink: 0;
            margin-right: 8px;

            vertical-align: text-top;
        }

        &--success {
            background-color: ${colors.successBase};
        }

        &--error {
            background-color: ${colors.warningBase};
        }

        &--info {
            background-color: ${colors.informationBase};
        }

        ${media.SM`
            margin-bottom: 0;
        `}

        a, button {
            color: white;
        }
    }
`;
