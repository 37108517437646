import React from 'react';
import { colors } from '@ovpn-ui/styles';

import { BoxOwnProps, Text } from '@sso/shared/core';

import { Container, Steps, Step } from './FlowSteps.styles';

type FlowStepsProps = BoxOwnProps & {
    steps?: number;
    step?: number;
    name?: string;
};

function FlowSteps({ name, steps, step, minHeight = 40, mb = 16, ...props }: FlowStepsProps) {
    return (
        <Container minHeight={minHeight} mb={mb} {...props}>
            {name && (
                <Text
                    style={{ textTransform: 'uppercase' }}
                    color={colors.neutralTextPlaceholder}
                    weight="semiBold"
                    variant="textXs"
                >
                    {name}
                </Text>
            )}
            {steps && (
                <Steps active={step}>
                    {[...Array(steps).keys()].map(key => (
                        <Step key={key} />
                    ))}
                </Steps>
            )}
        </Container>
    );
}

export default FlowSteps;
