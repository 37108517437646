import { storeFactory } from '@ovpn-ui/utils';

import { ProductType, AuthType, OwnerAuthType } from '@sso/shared/constants';

const PREV_CREDS_KEY = 'prev-creds';
const PREFIX = 'open-vpn-sso';

const { set: setPrevCreds, get: getPrevCreds } = storeFactory(PREV_CREDS_KEY, PREFIX);

export type UsedCreds = {
    authType?: AuthType | OwnerAuthType;
    product: ProductType;
    username?: string;
    domain?: string;
};

export function getUsedCreds(): UsedCreds[] | [] {
    const prevCreds = getPrevCreds();

    return Array.isArray(prevCreds) ? prevCreds : [];
}

export function hasUsedCreds(): boolean {
    return !!getUsedCreds().length;
}

export function storeUsedCreds(creds: UsedCreds) {
    try {
        setPrevCreds(
            [creds].concat(
                getUsedCreds().filter(
                    (c: UsedCreds) =>
                        `${c.username}${c.domain}` !== `${creds.username}${creds.domain}`,
                ),
            ),
        );
    } catch {
        // do nothing
    }
}
