import { combineReducers } from '@reduxjs/toolkit';

import { productSlice } from '@sso/shared/redux/product/product.slice';
import { modalSlice } from '@sso/shared/redux/modal/modal.slice';
import { authSlice } from '@sso/shared/redux/auth/auth.slice';

import apiSlice from './apiSlice';

const reducer = combineReducers({
    [productSlice.name]: productSlice.reducer,
    [modalSlice.name]: modalSlice.reducer,
    [authSlice.name]: authSlice.reducer,

    [apiSlice.reducerPath]: apiSlice.reducer,
});

export type RootState = ReturnType<typeof reducer>;

export default reducer;
