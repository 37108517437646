import React, { useEffect, useMemo } from 'react';
import { useLocation, useParams, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { selectUserData, selectLoggedIn, selectIsRestrained } from '@sso/shared/redux';
import ErrorBoundary from '@sso/shared/components/ErrorBoundary';
import { useAppDispatch } from '@sso/shared/store';
import { RouteParams } from '@sso/shared/types';
import { paths } from '@sso/shared/constants';
import { Spinner } from '@sso/shared/core';

import { validateGuard, getPathnameBase } from './GuardedRouter.utils';
import { GuardedComponentProps } from './GuardedRouter.props';
import { defaultGuard } from './GuardedRouter.constants';
import { accessDenied } from './GuardedRouter.actions';

export default function GuardedComponent({
    component: Component,
    guard = defaultGuard,
    ...props
}: GuardedComponentProps) {
    const dispatch = useAppDispatch();

    const isRestrained = useSelector(selectIsRestrained);
    const loggedIn = useSelector(selectLoggedIn);
    const userData = useSelector(selectUserData);

    const params = useParams<RouteParams>();
    const { pathname } = useLocation();

    const access = useMemo(
        () => validateGuard({ userData, loggedIn, isRestrained, params }, guard),
        [userData, loggedIn, isRestrained, params, guard],
    );

    const allowed = useMemo(
        () => Object.values(access).every(perm => typeof perm === 'boolean' && perm),
        [access],
    );

    // biome-ignore lint/correctness/useExhaustiveDependencies:
    useEffect(() => {
        if (!allowed) {
            dispatch(accessDenied({ pathname, access }));
        }
    }, [allowed, access]);

    if (!Component) {
        return null;
    }

    if (typeof access.params === 'boolean' && !access.params) {
        return <Redirect to={getPathnameBase(pathname)} />;
    }

    if (typeof access.webAuth === 'boolean' && !access.webAuth) {
        return <Redirect to={paths.signin} />;
    }

    if (!allowed) {
        return <Spinner absolute />;
    }

    return (
        <ErrorBoundary>
            <Component {...props} />
        </ErrorBoundary>
    );
}
