import { useCallback } from 'react';
import { setAuthToken } from '@ovpn-ui-toolkit/mf-common';
import { clearCookies } from '@ovpn-ui/utils';

import { useLogoutMutation } from '@sso/shared/redux';
import { useAppDispatch } from '@sso/shared/store';

export default function useLogout() {
    const [logout] = useLogoutMutation();
    const dispatch = useAppDispatch();

    return useCallback(async () => {
        try {
            await logout().unwrap();
            clearCookies();
        } catch {
            clearCookies();
            dispatch(setAuthToken());
        }
    }, [dispatch, logout]);
}
