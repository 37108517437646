import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { parseSearch } from '@ovpn-ui/utils';

import { storeUsedCreds, getProductRedirect, makeProductRedirect } from '@sso/shared/utils';
import { useAppDispatch, useAppStore } from '@sso/shared/store';
import { ProductType } from '@sso/shared/constants';
import {
    selectProductParams,
    navigateProduct,
    selectUserData,
    selectProduct,
    selectDomain,
} from '@sso/shared/redux';

import { useNavigate } from '../navigation';

export default function useProductNavigate() {
    const { search } = useLocation();
    const navigate = useNavigate();

    const dispatch = useAppDispatch();
    const store = useAppStore();

    return useCallback(
        ({ forceRedirect, queryParams: forceQueryParams } = {}) => {
            const storedProductParams = selectProductParams(store.getState())!;
            const userData = selectUserData(store.getState());
            const product = selectProduct(store.getState());
            const domain = selectDomain(store.getState());

            const { redirect: storedRedirect, ...productParams } = storedProductParams;

            const redirect = getProductRedirect({
                userDomains: userData.domains,
                storedRedirect,
                forceRedirect,
                product,
                domain,
            });

            if (
                redirect === window._sso_env_.PRODUCT_HOST &&
                productParams.explicitProductSelect &&
                !forceRedirect
            ) {
                navigate({ key: 'signinProductSelect' });
                return;
            }

            dispatch(navigateProduct());

            if (product === ProductType.Cloud && domain && userData.domains.includes(domain)) {
                storeUsedCreds({
                    authType:
                        userData.authOptions.length === 1 ? userData.authOptions[0] : undefined,
                    username: userData.username,
                    product,
                    domain,
                });
            }

            const { origin, pathname, search: redirectSearch } = new URL(redirect);

            const queryParams = {
                ...productParams,
                ...parseSearch(redirectSearch),
                ...parseSearch(search),
                ...forceQueryParams,
            };

            makeProductRedirect(`${origin}${pathname}`, queryParams);
        },
        [dispatch, navigate, search, store],
    );
}
