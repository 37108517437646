import { Action, AnyAction, Dispatch, Middleware } from 'redux';
import { Hub, User, Event } from '@sentry/browser';
import jwtDecode from 'jwt-decode';

import { Sentry, addSentryBreadcrumb, initSentry, isCloudAuth } from '@sso/shared/utils';

import type { RootState } from './reducer';

const getType = (action: AnyAction) => action.type;
const getUndefined = () => undefined;
const identity = (x: any) => x;
const filter = () => true;

type Options<S> = {
    breadcrumbMessageFromAction?: ((action: Action) => any) | undefined;
    filterBreadcrumbActions?: ((action: Action) => boolean) | undefined;
    breadcrumbDataFromAction?: ((action: Action) => any) | undefined;
    actionTransformer?: ((action: Action) => any) | undefined;
    getTags?: ((state: S) => Event['tags']) | undefined;
    stateTransformer?: ((state: S) => any) | undefined;
    getUserContext?: ((state: S) => User) | undefined;
    breadcrumbCategory?: string | undefined;
};

export const createSentryMiddleware = <S>(options: Options<S> = {}): Middleware<unknown, S> => {
    const {
        breadcrumbDataFromAction = getUndefined,
        breadcrumbMessageFromAction = getType,
        breadcrumbCategory = 'redux-action',
        filterBreadcrumbActions = filter,
        actionTransformer = identity,
        stateTransformer = identity,
        getUserContext,
        getTags,
    } = options;

    initSentry();

    return store => {
        // assigning null is a workaround since sentry api normalizes the store data
        // and converts undefined to '[undefined]'
        let lastAction: AnyAction | null = null;

        Sentry((hub: Hub) => {
            hub.configureScope(scope => {
                scope.addEventProcessor(_event => {
                    const state = store.getState();
                    const event = _event;

                    event.extra = {
                        ...event.extra,
                        lastAction: actionTransformer(lastAction as AnyAction),
                        state: stateTransformer(state),
                    };

                    if (getUserContext) {
                        event.user = {
                            ...event.user,
                            ...getUserContext(state),
                        };
                    }

                    if (getTags) {
                        Object.entries(getTags(state) || {}).forEach(([key, value]) => {
                            event.tags = {
                                ...event.tags,
                                [key]: value,
                            };
                        });
                    }

                    return event;
                });
            });
        });

        return (next: Dispatch) => (action: AnyAction) => {
            if (filterBreadcrumbActions(action)) {
                addSentryBreadcrumb({
                    message: breadcrumbMessageFromAction(action),
                    data: breadcrumbDataFromAction(action),
                    category: breadcrumbCategory,
                });
            }

            lastAction = action;
            return next(action);
        };
    };
};

function getUserContext(state: RootState) {
    try {
        const jwt = jwtDecode(state.auth.token!);
        return {
            data: JSON.stringify(jwt, null, 2),
        };
    } catch {
        return {};
    }
}

function breadcrumbDataFromAction(action: AnyAction) {
    return (
        action.payload && {
            payload: action.payload,
        }
    );
}

function filterBreadcrumbActions(action: AnyAction) {
    return !action.type.startsWith('api');
}

function getTags() {
    try {
        return {
            webAuth: isCloudAuth(),
        };
    } catch {
        return {};
    }
}

export default createSentryMiddleware<RootState>({
    breadcrumbDataFromAction,
    filterBreadcrumbActions,
    getUserContext,
    getTags,
});
