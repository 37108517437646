import { generatePath, matchPath } from 'react-router-dom';

import { Params, RouteParams, RouteProduct } from '@sso/shared/types';
import {
    navigation as navigationConst,
    fallback as fallbackConst,
    routes as routesConst,
    params as paramsConst,
    domainRoutes,
    paths,
    RouteKey,
    Route,
} from '@sso/shared/constants';

export const mapRouteToPath = (params: RouteParams, routes: Route[]) =>
    routes
        .map(route => {
            try {
                return generatePath(route, params);
            } catch {
                return null;
            }
        })
        .filter(path => !!path);

export const getKeyPath = (
    routeKey?: RouteKey,
    params: Params = {} as Params,
    fallback: Route | Route[] = [],
) => {
    const pathParams = Object.entries(params).reduce(
        (p, [key, value]) => ({ ...p, [key]: value?.toLowerCase() }),
        {} as RouteParams,
    );

    const navigation = {
        ...(pathParams.product ? navigationConst[pathParams.product as RouteProduct] : {}),
        ...navigationConst.base,
    };

    const route = routeKey && navigation[routeKey as keyof typeof navigation];

    const defaultFallback = pathParams.product
        ? fallbackConst[pathParams.product]
        : [routesConst.signinProductSelect];

    const routes = [
        ...(Array.isArray(route) ? route : [route]),
        ...(Array.isArray(fallback) ? fallback : [fallback]),
        ...defaultFallback,
    ].filter(Boolean) as Route[];

    return mapRouteToPath(pathParams, routes)[0] as string;
};

export const getPathParams = (pathname: string) => {
    // baseRoutes should come before domainRoutes in order not to treat their urls as :domain param
    // except routesConst.signin in order to still capture :domain param if it is present
    const pathMatch = matchPath<RouteParams>(pathname, {
        path: [
            ...Object.values(routesConst).filter(t => t !== routesConst.signin),
            ...Object.values(domainRoutes),
            routesConst.signin,
            `${paths.password}${paramsConst.product}`,
            `${paths.signin}${paramsConst.product}`,
            `${paths.signup}${paramsConst.product}`,
        ],
    });

    return {
        product: pathMatch?.params?.product,
        domain: pathMatch?.params?.domain,
    };
};
