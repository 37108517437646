import { matchPath, useLocation } from 'react-router-dom';
import { setAuthToken } from '@ovpn-ui-toolkit/mf-common';
import { clearCookies } from '@ovpn-ui/utils';
import { useEffect } from 'react';
import omit from 'lodash/omit';

import { setProduct, setDomain, setProductParams } from '@sso/shared/redux';
import { ProductType, routes } from '@sso/shared/constants';
import { useAppDispatch } from '@sso/shared/store';
import {
    getStorageProductParams,
    getSearchProductParams,
    getPathProductParams,
    getEnvProductParams,
    validateProduct,
    isCloudAuth,
    ls,
} from '@sso/shared/utils';

export default function useProductParams() {
    const { pathname, search } = useLocation();

    const dispatch = useAppDispatch();

    // biome-ignore lint/correctness/useExhaustiveDependencies:
    useEffect(() => {
        if (window._env_) {
            const { product, domain } = getEnvProductParams();

            dispatch(setProduct(product));
            dispatch(setDomain(domain));
            dispatch(setProductParams(undefined));
            return;
        }

        const {
            domain: storedDomain,
            product: storedProduct,
            ...storedProductParams
        } = getStorageProductParams();

        const { domain: pathDomain, product: pathProduct } = getPathProductParams(pathname);

        let searchDomain;
        let searchProduct;
        let searchProductParams;

        if (search?.length) {
            const searchParams = getSearchProductParams(search);

            if (searchParams.clean) {
                ls.clearLocalStorage();
                clearCookies();

                dispatch(setAuthToken()); // clean auth token from state
                dispatch(setProductParams(undefined));
                return;
            }

            if (searchParams.amznt) ls.setAmazonToken(searchParams.amznt);
            if (searchParams.fpr) ls.setFprToken(searchParams.fpr);

            if (
                !!matchPath(pathname, routes.signinOauth) ||
                !!matchPath(pathname, routes.signupOauth)
            ) {
                searchProduct = validateProduct(searchParams.state as string);
            } else {
                searchProductParams = omit(searchParams, ['product', 'domain']);
                searchProduct = searchParams.product;
                searchDomain = searchParams.domain;
            }
        }

        const productParams = searchProductParams || storedProductParams;
        const domain = pathDomain || searchDomain || storedDomain;
        const product = !isCloudAuth()
            ? pathProduct || searchProduct || storedProduct
            : ProductType.Cloud;

        dispatch(setProduct(product as ProductType));
        dispatch(setDomain(domain as string));
        dispatch(setProductParams(productParams));
    }, []);
}
