import styled from 'styled-components';
import { NavPusher as _NavPusher } from '@ovpn-ui/navigation';
import { media } from '@ovpn-ui/styles';

export const NavPusher = styled(_NavPusher)`
    ${media.SM`
        margin-top: calc(var(--sidebar-top-offset, 0) + 56px);
    `}
`;

export const AppWrapper = styled.div`
    position: relative;

    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    max-width: 100%;
    padding: 32px;
    overflow: auto;

    ${media.SM`
        padding: 32px 24px;
    `}

    ${media.XS`
        padding: 32px 16px;
    `}
`;
