import React from 'react';
import ReactDOM from 'react-dom';
import { ResetStyles, GlobalStyles } from '@ovpn-ui/styles';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import 'react-perfect-scrollbar/dist/css/styles.min.css';
import '@sso/shared/styles.css';

import { selectToken, selectDecodedToken } from '@sso/shared/redux';
import { configureStore } from '@sso/shared/store';
import { logDebugInfo } from '@sso/shared/utils';

import App from './containers/App';

const store = configureStore('sso-common');

logDebugInfo(selectToken(store.getState()), selectDecodedToken(store.getState()));

const MOUNT_NODE = document.getElementById('sso-app');
MOUNT_NODE?.setAttribute('app-version', window._sso_env_.BUILD_VERSION);

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
        <GlobalStyles />
        <ResetStyles />
    </Provider>,
    MOUNT_NODE,
);
