import React from 'react';
import { Breadcrumbs as CoreBreadcrumbs } from '@ovpn-ui/core';

import { withBoxStyles } from '@sso/shared/core/Box';
import Anchor from '@sso/shared/core/Anchor';

import { BreadcrumbsProps } from './Breadcrumbs.props';

function Breadcrumbs({ breadcrumbs, ...props }: BreadcrumbsProps) {
    return (
        <CoreBreadcrumbs {...props}>
            {breadcrumbs.map(({ name, id, ...breadcrumb }, index) => (
                <Anchor disabled={index === breadcrumbs.length - 1} {...breadcrumb} key={id}>
                    {name}
                </Anchor>
            ))}
        </CoreBreadcrumbs>
    );
}

export default withBoxStyles(Breadcrumbs);
