import React from 'react';
import styled from 'styled-components';

import type { BoxProps, StyledBoxProps } from './Box.props';
import {
    mapProps as defaultMapProps,
    selectAlignmentStyle,
    selectCommonStyle,
    selectDisplayStyle,
} from './Box.utils';

const defaultElement = 'div';

export default function withBoxStyles<Props, T extends React.ElementType = typeof defaultElement>(
    Component: React.ComponentType<Props>,
    mapProps = defaultMapProps,
) {
    const Styled = styled(Component)`
        ${selectAlignmentStyle};
        ${selectDisplayStyle};
        ${selectCommonStyle};
    ` as React.ComponentType<StyledBoxProps<Props>>;

    function Boxed<E extends React.ElementType = T>(
        { as, ...props }: BoxProps<E, Props>,
        ref: React.ForwardedRef<E>,
    ) {
        return (
            <Styled ref={ref} {...(mapProps(props) as StyledBoxProps<Props>)} forwardedAs={as} />
        );
    }

    const displayName = Component.displayName || Component.name || 'Component';

    Boxed.displayName = `withBoxStyles(${displayName})`;

    return React.forwardRef(Boxed) as <E extends React.ElementType = T>(
        props: BoxProps<E, Props>,
    ) => JSX.Element;
}
