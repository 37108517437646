import { createApi } from '@reduxjs/toolkit/query/react';

import baseQuery from './baseQuery';

const apiSlice = createApi({
    endpoints: () => ({}),
    baseQuery,
});

export default apiSlice;
