import { request, logErrorWithOnlineCheck } from '@ovpn-ui/utils';

export async function getIp() {
    // Call own node.js endpoint which will look at request headers and return request ip.
    const options = {
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
        },
    };

    try {
        const response = await request('/myip', options, undefined, logErrorWithOnlineCheck);
        return response.ip || 'unknown';
    } catch {
        return 'unknown';
    }
}
