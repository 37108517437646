import { toast as eventToast } from '@cloud/shared';
import { toast } from 'react-toastify';

function toastProvider() {
    // window._env_ is present in case of app being used as MF
    // soo we use event based toaster mounted in the root
    return window._env_ ? eventToast : toast;
}

export default toastProvider();
