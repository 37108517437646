import React from 'react';
import { colors } from '@ovpn-ui/styles';

import { Anchor, Text } from '@sso/shared/core';
import { useKeyPath } from '@sso/shared/hooks';
import { routes } from '@sso/shared/constants';

export function SignupLink() {
    const signupPath = useKeyPath({
        fallback: routes.signupProductSelect,
        key: 'signup',
    });

    return (
        <Text color={colors.neutralTextSubtitle} variant="textMd">
            Don&apos;t have an account? <Anchor to={signupPath}>Sign Up</Anchor>
        </Text>
    );
}
