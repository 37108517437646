import { Dropdown, DropdownProps } from '@ovpn-ui/core';

import { BoxOwnProps, mapProps as defaultMapProps, withBoxStyles } from '@sso/shared/core/Box';

const mapProps = ({ autocomplete = false, ...props }: BoxOwnProps & Partial<DropdownProps>) => ({
    ...defaultMapProps(props),
    autocomplete,
});

export default withBoxStyles(Dropdown, mapProps);
