import { paths, OwnerAuthType, ProductType } from '@sso/shared/constants';
import { isCloudAuth, validateDomain } from '@sso/shared/utils';

import type {
    PermissionsRequirement,
    PermissionsValidator,
    ParamsValidator,
    NoAuthValidator,
    AuthValidator,
    GuardResponse,
    GuardPayload,
    GuardConfig,
} from './GuardedRouter.props';

export const validateAuth: AuthValidator = loggedIn => loggedIn;

export const validateNoAuth: NoAuthValidator = (loggedIn, isRestrained) =>
    isRestrained || !loggedIn;

export const validateWebAuth = (required: boolean) => (required ? true : !isCloudAuth());

export const validateParams: ParamsValidator = (
    { product, domain, provider },
    required = Object.values(ProductType),
) =>
    (!provider || Object.values(OwnerAuthType).includes(provider.toUpperCase() as OwnerAuthType)) &&
    (!product || required.includes(product.toUpperCase() as ProductType)) &&
    (!domain || !!validateDomain(domain));

export const validatePermissions: PermissionsValidator = (userData, required) => {
    if (userData.username && Array.isArray(required)) {
        return !!userData.authorities.filter(value => required.includes(value)).length;
    }

    if (userData.username && typeof required === 'function') {
        try {
            return required(userData);
        } catch {
            return false;
        }
    }

    return false;
};

export const validateGuard = (
    { loggedIn, isRestrained, userData, params }: GuardPayload,
    guard: GuardConfig,
): GuardResponse =>
    Object.entries(guard).reduce((access, [key, required]) => {
        switch (key) {
            case 'permissions':
                return {
                    permissions: validatePermissions(userData, required as PermissionsRequirement),
                    ...access,
                };
            case 'notLoggedIn':
                return {
                    notLoggedIn: validateNoAuth(loggedIn, isRestrained),
                    ...access,
                };
            case 'loggedIn':
                return {
                    loggedIn: validateAuth(loggedIn),
                    ...access,
                };
            case 'params':
                return {
                    params: validateParams(params, required as ProductType[]),
                    ...access,
                };
            case 'webAuth':
                return {
                    webAuth: validateWebAuth(required as boolean),
                    ...access,
                };
            default:
                return access;
        }
    }, {});

export const getPathnameBase = (pathname: string) => {
    const basePathnames = [paths.signin, paths.signup, paths.password] as string[];
    const redirect = pathname.split('/').slice(0, 2).join('/');

    return basePathnames.includes(redirect) ? redirect : paths.signin;
};
