import React, { Component } from 'react';
import { colors } from '@ovpn-ui/styles';

import { Spinner, Button, Text } from '@sso/shared/core';

import { Container } from './ErrorBoundary.styles';
import ErrorSvg from './ErrorSvg';

const pageReloadRegex = /^#updated:(\d)$/;
const pageReloadMaxCount = 5;

type ErrorBoundaryState = {
    reloadCount?: number;
    showError?: boolean;
};

type ErrorBoundaryProps = {
    children?: React.ReactNode;
};

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);

        this.state = {
            reloadCount: undefined,
            showError: undefined,
        };
    }

    static getDerivedStateFromError(error: Error) {
        if (error?.name === 'ChunkLoadError') {
            const reloadHash = window.location.hash || '#updated:0';
            const match = reloadHash.match(pageReloadRegex);
            const reloadCount = Number(match?.[1] ?? 0);

            return {
                showError: true,
                reloadCount,
            };
        }

        return { showError: true };
    }

    componentDidCatch() {
        const { reloadCount } = this.state;

        if (typeof reloadCount === 'number' && reloadCount < pageReloadMaxCount) {
            window.location.hash = `updated:${reloadCount + 1}`;
            window.location.reload();
        }
    }

    handleButtonClick = () => {
        if (window.zE) {
            window.zE(window.zE.widget, 'open');
        }
    };

    render() {
        const { showError, reloadCount } = this.state;
        const { children } = this.props;

        if (!showError) {
            return children;
        }

        if (showError && typeof reloadCount === 'number' && reloadCount < pageReloadMaxCount) {
            return <Spinner absolute />;
        }

        return (
            <Container>
                <ErrorSvg />
                <Text
                    color={colors.neutralTextTitle}
                    variant="displayMd"
                    weight="medium"
                    m="16px 0 24px"
                >
                    Sorry! Something went wrong
                </Text>
                <Text color={colors.neutralTextSubtitle} variant="textMd" mb={24} center>
                    Please refresh your page to try again. <br />
                    If this issue is reproduced, please send us a feedback.
                </Text>
                <Button onClick={this.handleButtonClick} variant="ghost" m="0 auto">
                    Report feedback
                </Button>
            </Container>
        );
    }
}

export default ErrorBoundary;
