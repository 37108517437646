import isNil from 'lodash/isNil';

import type { BoxOwnProps, StyledBoxOwnProps } from './Box.props';

export const defaultPx = (prop: string | number) => (typeof prop === 'number' ? `${prop}px` : prop);
export const asIs = (t: string) => t;

export const mapProps = ({
    minHeight,
    maxHeight,
    minWidth,
    maxWidth,
    fullWidth,
    center,
    inline,
    flex,

    m,
    mb,
    mt,
    ml,
    mr,

    p,
    pb,
    pt,
    pl,
    pr,

    ...props
}: BoxOwnProps): StyledBoxOwnProps => ({
    $maxHeight: maxHeight,
    $minHeight: minHeight,
    $maxWidth: maxWidth,
    $minWidth: minWidth,
    $fullWidth: fullWidth,
    $center: center,
    $inline: inline,
    $flex: flex,

    $m: m,
    $mb: mb,
    $mt: mt,
    $ml: ml,
    $mr: mr,

    $p: p,
    $pb: pb,
    $pt: pt,
    $pl: pl,
    $pr: pr,

    ...props,
});

export const propsToStyleMap = {
    $maxHeight: { name: 'max-height', apply: defaultPx },
    $minHeight: { name: 'min-height', apply: defaultPx },
    $maxWidth: { name: 'max-width', apply: defaultPx },
    $minWidth: { name: 'min-width', apply: defaultPx },
    $fullWidth: { name: 'width', apply: () => '100%' },

    $m: { name: 'margin', apply: asIs },
    $mb: { name: 'margin-bottom', apply: defaultPx },
    $mt: { name: 'margin-top', apply: defaultPx },
    $ml: { name: 'margin-left', apply: defaultPx },
    $mr: { name: 'margin-right', apply: defaultPx },

    $p: { name: 'padding', apply: asIs },
    $pb: { name: 'padding-bottom', apply: defaultPx },
    $pt: { name: 'padding-top', apply: defaultPx },
    $pl: { name: 'padding-left', apply: defaultPx },
    $pr: { name: 'padding-right', apply: defaultPx },
};

export function selectCommonStyle(props: StyledBoxOwnProps) {
    return Object.keys(propsToStyleMap)
        .filter(k => !isNil(props[k as keyof typeof props]))
        .map(k => {
            const { name, apply } = propsToStyleMap[k as keyof typeof propsToStyleMap];
            const value = (apply as (prop: unknown) => string)(props[k as keyof typeof props]);

            return `${name}: ${value};`;
        })
        .join('');
}

export function selectDisplayStyle({ $flex, $inline }: StyledBoxOwnProps) {
    if ($flex) {
        return `display: ${$inline ? 'inline-flex' : 'flex'}`;
    }

    if ($inline) {
        return `display: ${$inline ? 'inline-block' : 'block'}`;
    }

    return '';
}

export function selectAlignmentStyle({ $flex, $center }: StyledBoxOwnProps) {
    if ($center) {
        return `${$flex ? 'align-items' : 'text-align'}: center;`;
    }

    return '';
}
