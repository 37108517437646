export const formatInput = (value?: any) => {
    if (!value || typeof value !== 'string') return value;

    return value.trim();
};

export const formatDomain = (value?: any) => {
    if (!value || typeof value !== 'string') return value;

    return value.trim().toLowerCase();
};

const createDigitFormat = (regExp: RegExp, matchRegExp: RegExp) => (value?: any) => {
    if (!value || typeof value !== 'string') return value;

    return regExp.test(value) ? value : (value.match(matchRegExp) || []).join('');
};

export const formatPhone = createDigitFormat(/^\+?([0-9])*$/, /^\+|\d/g);

export const formatNumber = createDigitFormat(/^([0-9])*$/, /\d/g);
