import React from 'react';
import { createSvgIcon } from '@ovpn-ui/icons';

export default createSvgIcon(
    <>
        <g clipPath="url(#clip0_613_52460)">
            <path
                d="M20.8244 26.9907L17.9004 19.5213C18.684 19.0985 19.3042 18.4264 19.6626 17.6113C20.0211 16.7962 20.0973 15.8848 19.8793 15.0215C19.6613 14.1582 19.1614 13.3924 18.4589 12.8452C17.7564 12.2981 16.8915 12.001 16.0011 12.001C15.1107 12.001 14.2457 12.2981 13.5432 12.8452C12.8407 13.3924 12.3409 14.1582 12.1228 15.0215C11.9048 15.8848 11.9811 16.7962 12.3395 17.6113C12.6979 18.4264 13.3181 19.0985 14.1017 19.5213L11.1777 26.992"
                stroke="#1A3967"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4 16C4 17.5759 4.31039 19.1363 4.91345 20.5922C5.5165 22.0481 6.40042 23.371 7.51472 24.4853C8.62902 25.5996 9.95189 26.4835 11.4078 27.0866C12.8637 27.6896 14.4241 28 16 28C17.5759 28 19.1363 27.6896 20.5922 27.0866C22.0481 26.4835 23.371 25.5996 24.4853 24.4853C25.5996 23.371 26.4835 22.0481 27.0866 20.5922C27.6896 19.1363 28 17.5759 28 16C28 14.4241 27.6896 12.8637 27.0866 11.4078C26.4835 9.95189 25.5996 8.62902 24.4853 7.51472C23.371 6.40042 22.0481 5.5165 20.5922 4.91345C19.1363 4.31039 17.5759 4 16 4C14.4241 4 12.8637 4.31039 11.4078 4.91345C9.95189 5.5165 8.62902 6.40042 7.51472 7.51472C6.40042 8.62902 5.5165 9.95189 4.91345 11.4078C4.31039 12.8637 4 14.4241 4 16Z"
                stroke="#1A3967"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_613_52460">
                <rect width="32" height="32" fill="white" />
            </clipPath>
        </defs>
    </>,
    { viewBox: '0 0 32 32', width: '32', height: '32' },
    'LogoIcon',
);
