import React from 'react';
import { AccountMicrosoft } from '@ovpn-ui/icons';

import { Button, ButtonOwnProps } from '@sso/shared/core';

function MicrosoftButton({ children, ...props }: ButtonOwnProps) {
    return (
        <Button
            data-testid="microsoft-signin-button"
            style={{ textTransform: 'none' }}
            variant="ghostBlue"
            {...props}
        >
            <AccountMicrosoft style={{ margin: '0 8px 0 0' }} />
            {children}
        </Button>
    );
}

export default MicrosoftButton;
